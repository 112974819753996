import {
  AUTH_ERROR,
  SET_USER,
  GET_CURRENT_USER,
  SET_CURRENT_USER,
  CLEAR_USER,
  GET_USER_ORGANIZATION,
} from './authTypes';
import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL;
// Register
export const registerUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}auth/register`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: SET_USER, payload: res.data });
    const resOrganization = await axios.get(`${url}organization/current`);
    const organization = resOrganization.data;
    dispatch({ type: GET_USER_ORGANIZATION, payload: organization });

    // Set Register Success Notification

    const user = res.data;
    const notification = {
      message: `Thank you ${user.firstName}, Please confirm your account through mail`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Registration Failed' });
  }
};
// Login User
export const login = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const res = await axios.post(`${url}auth/login`, data);

    dispatch({ type: CLEAR_LOADING });
    const user = res.data;

    const notification = {
      message: `Welcome ${user.firstName}`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: SET_USER, payload: res.data });
    dispatch(getCurrentUser());

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

export const loginMobile = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const res = await axios.post(`${url}auth/login/mobile/confirm`, data);

    dispatch({ type: CLEAR_LOADING });
    const user = res.data;

    const notification = {
      message: `Welcome ${user.firstName}`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    dispatch({ type: SET_USER, payload: res.data });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 3000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Send Code
export const sendCode = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    await axios.post(`${url}auth/login/mobile`, data);

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Great, Please enter the code sent`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Send Code', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Get Current User

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`${url}users/current`);

    dispatch({ type: GET_CURRENT_USER, payload: res.data });

    const resOrganization = await axios.get(`${url}organization/current`);
    const organization = resOrganization.data;
    dispatch({ type: GET_USER_ORGANIZATION, payload: organization });

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);

    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Update Current User
export const updateCurrentUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    dispatch({ type: CLEAR_LOADING });

    dispatch({ type: SET_CURRENT_USER, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};
// Save Current User
export const saveCurrentUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(`${url}auth`, data);

    dispatch({ type: CLEAR_LOADING });

    // dispatch({ type: GET_CURRENT_USER, payload: res.data });

    const notification = {
      message: `Profile Updated`,
      type: 'info',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);

    console.log(error);
    // dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Log Out

export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Log Out Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);

    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(`${url}auth/forgot-password`, { email });

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Please check your Email for further instructions`,
      type: 'info',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);

    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Reset Password
export const resetPassword =
  ({ password, emailToken }) =>
  async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      await axios.post(`${url}auth/password-reset`, { password, emailToken });

      dispatch({ type: CLEAR_LOADING });

      const notification = {
        message: `Account Reset. Please Login`,
        type: 'success',
      };

      dispatch({ type: SET_NOTIFICATION, payload: notification });

      setTimeout(() => {
        dispatch({ type: CLEAR_NOTIFICATION });
      }, 2000);
    } catch (error) {
      dispatch({
        type: SET_NOTIFICATION,
        payload: { message: 'Ooops :' + error, type: 'error' },
      });
      dispatch({ type: CLEAR_LOADING });

      setTimeout(() => {
        dispatch({ type: CLEAR_NOTIFICATION });
      }, 2000);

      dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
    }
  };

// Activate Password
export const activateUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}auth/activate`, data);

    dispatch({ type: SET_USER, payload: res.data });
    dispatch(getCurrentUser());

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Account Activated. Welcome ${res.data.firstName}`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error.message, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Failed' });
  }
};
