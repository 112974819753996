import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ORGANIZATION_ERROR,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  REMOVE_ORGANIZATION,
} from './organizationTypes';
import { clearLoading } from '../notification/notificationActions';

const url = process.env.REACT_APP_API_URL + 'organization';

let notification;

// Create Organization

export const addOrganization = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const unit = res.data;
    notification = {
      message: `Organization Created ${unit.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: ORGANIZATION_ERROR,
      payload: 'Failed to Create Organization',
    });
  }
};

// Update Organization

export const updateOrganization = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_UNIT, payload: res.data });
    // Set Register Success Notification

    const organization = res.data;
    const notification = {
      message: `Updated Organization ${organization.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: ORGANIZATION_ERROR,
      payload: 'Failed to Update Organization',
    });
  }
};

// Update Current Organization

export const updateCurrentOrganization = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    // TODO: Work on Auth Guard
    if (!token) {
      notification.message = 'Unauthorized';
      notification.type = 'error';

      dispatch({ type: SET_NOTIFICATION, payload: notification });

      return;
    }
    dispatch({ type: SET_LOADING });
    await axios.put(`${url}/admin`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_UNIT, payload: res.data });
    // Set Register Success Notification

    // const organization = res.data;
    notification = {
      message: `Updated Current Organization successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: ORGANIZATION_ERROR,
      payload: 'Failed to Update Organization',
    });
  }
};

// Get Organizations
export const getOrganizations = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const organizations = res.data;
    dispatch({ type: GET_ORGANIZATIONS, payload: organizations });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    dispatch({ type: CLEAR_LOADING });

    console.error(e);
  }
};

// Get Organization By ID
export const getOrganizationByID = (id) => async (dispatch) => {
  const token = localStorage.getItem('token');
  // TODO: Work on Auth Guard

  if (!token) {
    notification.message = 'Unauthorized';
    notification.type = 'error';

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    return;
  }
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const organization = res.data;
    dispatch({ type: GET_ORGANIZATION, payload: organization });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: ORGANIZATION_ERROR,
      payload: 'Failed to Get Organization ',
    });
  }
};

// Delete Organization
export const deleteOrganization = (id) => async (dispatch) => {
  const token = localStorage.getItem('token');
  // TODO: Work on Auth Guard

  if (!token) {
    notification.message = 'Unauthorized';
    notification.type = 'error';

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    return;
  }
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const notification = {
      message: `Deleted Organization`,
      type: 'info',
    };

    dispatch({ type: REMOVE_ORGANIZATION, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: ORGANIZATION_ERROR,
      payload: 'Failed to Delete Organization',
    });
  }
};

// // Change Organization Admin

// export const changeOrganizationAdmin = data => async dispatch => {
//   try {
//     const token = localStorage.getItem('token');
//     // TODO: Work on Auth Guard

//     if (!token) {
//       notification.message = 'Unauthorized';
//       notification.type = 'error';

//       dispatch({ type: SET_NOTIFICATION, payload: notification });

//       return;
//     }

//     dispatch({ type: SET_LOADING });

//     await axios.post(
//       `${url}/${data.organizationId}/admin`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       },
//       {
//         userId: data.userId
//       }
//     );

//     dispatch({ type: CLEAR_LOADING });
//     // Set Register Success Notification

//     notification = {
//       message: `User Admin Changed successfully`,
//       type: 'success'
//     };

//     dispatch({ type: SET_NOTIFICATION, payload: notification });
//     dispatch({ type: CLEAR_LOADING });
//     setTimeout(() => {
//       dispatch({ type: CLEAR_NOTIFICATION });
//     }, 2000);
//   } catch (error) {
//     dispatch({ type: CLEAR_LOADING });

//     console.log(error);
//     dispatch({
//       type: ORGANIZATION_ERROR,
//       payload: 'Failed to Change User Admin'
//     });
//   }
// };
