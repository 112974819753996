import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../features/role/roleActions';
import { addUser, addUserAdmin } from '../../features/user/userActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { getOrganizations } from '../../features/organization/organizationActions';
import { ROLE_SUPER_ADMIN } from '../../features/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddUser = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.auth.current);
  const page = useSelector((state) => state.page.page);
  const roles = useSelector((state) => state.role.roles);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getOrganizations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      page &&
      page.includes('Add User') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/users');
    }
    // eslint-disable-next-line
  }, [notification]);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    roleId: null,
    mobileNumber: '',
    email: '',
    sendActivationMail: true,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // e.preventDefault();
    if (current && current.roleName === ROLE_SUPER_ADMIN) {
      dispatch(addUserAdmin(form));
    } else {
      dispatch(addUser(form));
    }
  };
  return (
    <Paper className={classes.root}>
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              type="number"
              id="mobileNumber"
              label="Mobile Number"
              name="mobileNumber"
              onChange={onChange}
              autoComplete="mobileNumber"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-role-simple">Role</InputLabel>

              <Select
                // inputRef={register}
                name="roleId"
                inputProps={{
                  name: 'roleId',
                  id: 'outlined-role-simple',
                }}
                value={form.roleId}
                onChange={onChange}
              >
                {roles &&
                  roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      <em>{role.name}</em>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {current && current.roleName === ROLE_SUPER_ADMIN && (
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-organization-simple">
                  Organization
                </InputLabel>
                <Select
                  // inputRef={register}
                  name="organizationId"
                  inputProps={{
                    name: 'organizationId',
                    id: 'outlined-organization-simple',
                  }}
                  value={form.organizationId}
                  onChange={onChange}
                >
                  {organizations &&
                    organizations.data.map((organization) => (
                      <MenuItem key={organization.id} value={organization.id}>
                        <em>{organization.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              label={'Send Activation Mail'}
              value={form.sendActivationMail}
              disabled
              control={
                <Switch
                  color="primary"
                  value={form.sendActivationMail}
                  checked={form.sendActivationMail}
                  onChange={(e) =>
                    setForm({ ...form, sendActivationMail: e.target.checked })
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              size="large"
              disabled={
                loading ||
                form.firstName.length === 0 ||
                form.lastName.length === 0 ||
                form.email.length === 0 ||
                form.mobileNumber.length === 0 ||
                !form.roleId
              }
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddUser;
