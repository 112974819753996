import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/lab/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import {
  updateOrganization,
  getOrganizationByID,
} from '../../features/organization/organizationActions';
import { useTheme } from '@mui/material/styles';
import { BlockPicker } from 'react-color';
import { countries } from '../../utils/countries';

const countryNames = countries.map((country) => country.shortName);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  userCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  color: {
    width: '100%',
    height: '14px',
    borderRadius: '2px',
  },
  swatchPrimary: {
    padding: '1rem',
    background: theme.palette.primary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  swatchSecondary: {
    padding: '1rem',
    background: theme.palette.secondary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const EditOrganization = ({ history, match }) => {
  const classes = useStyles();

  const theme = useTheme();

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.organization.current);

  useEffect(() => {
    dispatch(getOrganizationByID(match.params.id));
    // eslint-disable-next-line
  }, []);

  const [form, setForm] = useState({
    id: null,
    name: null,
    description: null,
    email: null,
    mobileNo: null,
    address: null,
    enabled: false,
    primaryColor: theme.palette.primary,
    secondaryColor: theme.palette.secondary,
    countries: [],
  });

  useEffect(() => {
    if (current) {
      form.id = current.id;
      form.name = current.name;
      form.description = current.description;
      form.email = current.email;
      form.enabled = current.enabled;
      form.mobileNo = current.mobileNo;
      form.address = current.address;
      form.active = current.active;
      form.primaryColor = current.primaryColor;
      form.secondaryColor = current.secondaryColor;
      form.countries = [...current.countries];
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(updateOrganization(form, form.id));
  };

  useEffect(() => {
    if (notification && notification.type === 'success') {
      history.push('/admin/organizations');
    }
    // eslint-disable-next-line
  }, [notification]);

  if (!current) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Paper style={{ padding: '1.5rem' }}>
            <Typography variant="body1" color="textSecondary">
              Details
            </Typography>
            <Grid style={{ paddingTop: '1rem' }} container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      variant="outlined"
                      required
                      fullWidth
                      onChange={onChange}
                      id="name"
                      label="Name"
                      value={form.name}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      variant="outlined"
                      fullWidth
                      type="text"
                      onChange={onChange}
                      id="description"
                      label="Description"
                      value={form.description}
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="email"
                      variant="outlined"
                      fullWidth
                      type="email"
                      onChange={onChange}
                      id="email"
                      label="Email"
                      value={form.email}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="mobileNo"
                      variant="outlined"
                      fullWidth
                      type="number"
                      onChange={onChange}
                      id="mobileNo"
                      label="Mobile No"
                      value={form.mobileNo}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="address"
                      variant="outlined"
                      fullWidth
                      type="text"
                      onChange={onChange}
                      id="address"
                      label="Address"
                      value={form.address}
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={form.enabled}
                        onChange={(e) =>
                          setForm({ ...form, enabled: e.target.checked })
                        }
                        value="enabled"
                        name="enabled"
                        color="primary"
                      />
                    }
                    value={form.enabled}
                    label="Enabled"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: 300 }}>
                    <InputLabel id="demo-countries-label">Countries</InputLabel>
                    <Select
                      labelId="demo-countries-label"
                      id="demo-countries"
                      multiple
                      value={form.countries}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setForm({
                          ...form,
                          countries:
                            typeof value === 'string'
                              ? value.split(',')
                              : value,
                        });
                      }}
                      input={<OutlinedInput label="Countries" />}
                      MenuProps={MenuProps}
                    >
                      {countryNames.map((country) => (
                        <MenuItem
                          key={country}
                          value={country}
                          style={getStyles(country, form.countries, theme)}
                        >
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2">
                  <strong>Primary Color</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2">
                  <strong>Secondary Color</strong>
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <BlockPicker
                  color={form.primaryColor}
                  colors={[
                    '#FF6900',
                    '#FCB900',
                    '#7BDCB5',
                    '#00D084',
                    '#8ED1FC',
                    '#0693E3',
                    '#ABB8C3',
                    '#EB144C',
                    '#F78DA7',
                    '#9900EF',
                  ]}
                  onChange={(color, event) => {
                    setForm({ ...form, primaryColor: color.hex });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <BlockPicker
                  color={form.secondaryColor}
                  colors={[
                    '#FF6900',
                    '#FCB900',
                    '#7BDCB5',
                    '#00D084',
                    '#8ED1FC',
                    '#0693E3',
                    '#ABB8C3',
                    '#EB144C',
                    '#F78DA7',
                    '#9900EF',
                  ]}
                  onChange={(color, event) => {
                    setForm({ ...form, secondaryColor: color.hex });
                  }}
                />
              </Grid>
            </Grid>
          </Paper>

          <Button
            type="submit"
            fullWidth
            style={{ marginTop: '2rem' }}
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
          >
            Update Organization
          </Button>

          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </form>
      </div>
    );
  }
};

export default EditOrganization;
