export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const SET_LOADING = 'SET_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_USER_ORGANIZATION = 'GET_USER_ORGANIZATION';
export const FORGOT_PASS = 'FORGOT_PASS';
