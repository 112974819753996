import { Link as RouterLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {
  sendCode,
  loginMobile,
  getCurrentUser,
} from '../../features/auth/authActions';
import PropTypes from 'prop-types';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://soja.co.ke/">
        Identigate
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginMobile = ({
  loginMobile,
  sendCode,
  notification,
  current,
  history,
  getCurrentUser,
}) => {
  const classes = useStyles();

  const [mobileNumber, setMobileNumber] = useState('');
  const [code, setCode] = useState('');
  const [codeEnabled, setCodeEnabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!codeEnabled) {
      sendCode({ mobileNumber });
    } else {
      loginMobile({ code });
    }
  };

  useEffect(() => {
    if (notification && notification.message.includes('code')) {
      setCodeEnabled(true);
    } else {
      setCodeEnabled(false);
    }
  }, [notification]);

  useEffect(() => {
    if (current) {
      history.push('/tenants/admin');
    } else if (localStorage.getItem('token') != null) {
      getCurrentUser();
    }
    // eslint-disable-next-line
  }, [current]);
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In using Mobile Number
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobileNumber"
              label="Mobile Number"
              name="mobileNumber"
              onChange={(e) => setMobileNumber(e.target.value)}
              // autoComplete="email"
              autoFocus
            />
            {codeEnabled && (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="code"
                label="Code"
                type="text"
                value={code}
                id="password"
                onChange={(e) => setCode(e.target.value)}

                // autoComplete="current-password"
              />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={3}>
                <RouterLink to="/forgot-password">Forgot password?</RouterLink>
              </Grid>
              <Grid item xs={3}>
                <RouterLink to="/" variant="body2">
                  {'Login'}
                </RouterLink>
              </Grid>
              <Grid item xs={3}>
                <RouterLink to="/signup" variant="body2">
                  {'Sign Up'}
                </RouterLink>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

LoginMobile.propTypes = {
  loginMobile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notification: state.notification.notification,
  current: state.auth.current,
});
export default connect(mapStateToProps, {
  sendCode,
  loginMobile,
  getCurrentUser,
})(LoginMobile);
