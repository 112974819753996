import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  updateUserRole,
  updateUserOrganization,
  updateUserStatus,
  makeUserOrgAdmin,
  resendActivationMail,
} from '../../../features/user/userActions';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '../../../features/role/roleTypes';
import { getOrganizations } from '../../../features/organization/organizationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AccountSettingsView = ({ user, history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const roles = useSelector((state) => state.role.roles);
  const organizations = useSelector(
    (state) => state.organization.organizations,
  );
  const currentUser = useSelector((state) => state.auth.current);

  const [roleId, setRoleId] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  useEffect(() => {
    setEnabled(user.isEnabled);
    setRoleId(user.roleId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (organizations) {
      setOrganizationId(user.organizationId);
    }
    // eslint-disable-next-line
  }, [organizations]);

  const handleEnableUser = (e) => {
    setEnabled(!isEnabled);
    dispatch(updateUserStatus(user.id, !isEnabled));
  };

  const handleChangeRole = () => {
    setConfirmRoleChangeOpen(false);
    dispatch(updateUserRole(user.id, roleId));
  };
  const handleChangeOrganization = () => {
    setConfirmOrganizationChangeOpen(false);
    dispatch(updateUserOrganization(user.id, organizationId));
  };

  const handleMakeOrgAdmin = () => {
    setMakeOrgAdminOpen(false);
    dispatch(makeUserOrgAdmin(user.id));
  };

  const handleResendActivationMail = () => {
    setResendActivationMailOpen(false);
    dispatch(resendActivationMail(user.id));
  };
  const [isEnabled, setEnabled] = useState(false);

  const [confirmRoleChangeOpen, setConfirmRoleChangeOpen] = useState(false);
  const [makeOrgAdminOpen, setMakeOrgAdminOpen] = useState(false);
  const [resendActivationMailOpen, setResendActivationMailOpen] =
    useState(false);
  const [confirmOrganizationChangeOpen, setConfirmOrganizationChangeOpen] =
    useState(false);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => setMakeOrgAdminOpen(true)}
            >
              Make User Organization Admin
            </Button>
          </Grid>
        )}
        {currentUser && !currentUser.isActivated && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => setResendActivationMailOpen(true)}
            >
              Resend Activation Email
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            label={isEnabled ? 'Enabled' : 'Disabled'}
            value={isEnabled ? 'Enabled' : 'Disabled'}
            control={
              <Switch
                color="primary"
                value={isEnabled ? 'Enabled' : 'Disabled'}
                checked={isEnabled}
                onChange={handleEnableUser}
              />
            }
          />
        </Grid>

        {roleId &&
          user &&
          (user.roleName === ROLE_ADMIN ||
            user.roleName === ROLE_SUPER_ADMIN) && (
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-role-simple">Role</InputLabel>
                <Select
                  value={roleId}
                  onChange={(e) => {
                    setRoleId(e.target.value);
                    setConfirmRoleChangeOpen(true);
                  }}
                  inputProps={{
                    name: 'roleId',
                    id: 'outlined-role-simple',
                  }}
                >
                  {roles &&
                    roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        <em>{role.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        {currentUser && currentUser.roleName === ROLE_SUPER_ADMIN && (
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-organization-simple">
                Organization
              </InputLabel>
              <Select
                // inputRef={register}
                name="organizationId"
                inputProps={{
                  name: 'organizationId',
                  id: 'outlined-organization-simple',
                }}
                value={organizationId}
                onChange={(e) => {
                  setOrganizationId(e.target.value);
                  setConfirmOrganizationChangeOpen(true);
                }}
              >
                {organizations &&
                  organizations.data.map((organization) => (
                    <MenuItem key={organization.id} value={organization.id}>
                      <em>{organization.name}</em>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {/* Dialog Confirm Role Change */}
      <Dialog
        open={confirmRoleChangeOpen}
        // TransitionComponent={Transition}
        // keepMount  ed
        onClose={() => {
          setConfirmRoleChangeOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Change Role
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to change {user.firstName} role to{' '}
            {roles.map((role) => (role.id === roleId ? role.name : ''))} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleChangeRole();
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setConfirmRoleChangeOpen(false);
            }}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Confirm Role Change */}
      <Dialog
        open={resendActivationMailOpen}
        // TransitionComponent={Transition}
        // keepMount  ed
        onClose={() => {
          setResendActivationMailOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Confirm Resend Mail
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Resend Activation Mail to {user.firstName} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleResendActivationMail();
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setResendActivationMailOpen(false);
            }}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Confirm Organization Change */}
      {organizations && (
        <Dialog
          open={confirmOrganizationChangeOpen}
          onClose={() => {
            setConfirmOrganizationChangeOpen(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title-organizaion">
            Confirm Change Organization
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to change {user.firstName} organization to{' '}
              {organizations.data.map((organization) =>
                organization.id === organizationId ? organization.name : '',
              )}{' '}
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleChangeOrganization();
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setConfirmOrganizationChangeOpen(false);
              }}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/*  */}
      {/* Dialog Make Org Admin Reset Change */}
      <Dialog
        open={makeOrgAdminOpen}
        // TransitionComponent={Transition}
        // keepMount  ed
        onClose={() => {
          setMakeOrgAdminOpen(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Make Org Admin </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to make {user.firstName} Org Admin ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMakeOrgAdmin} color="primary">
            Yes
          </Button>
          <Button
            onClick={() => {
              setMakeOrgAdminOpen(false);
            }}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/*  */}
    </Paper>
  );
};

export default AccountSettingsView;
