import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MoneyIcon from '@mui/icons-material/Money';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NotesIcon from '@mui/icons-material/Notes';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PAYMENT_TYPES } from '../../features/payment/paymentTypes';
import { submitPayment } from '../../features/payment/paymentActions';
import { NumberFormatCustom } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const MakePaymentDialog = ({ makePaymentOpen, handleMakePaymentClose }) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const loading = useSelector((state) => state.notification.loading);
  const billing = useSelector((state) => state.billing.current);

  const [form, setForm] = useState({
    type: PAYMENT_TYPES.CASH,
    notes: '',
    amount: 0,
    sendReceipt: false,
    billingId: billing.id,
  });

  const handleSubmit = () => {
    dispatch(submitPayment(form));
    handleMakePaymentClose();
  };

  const onChange = (e) => {
    if (e.target.name === 'mode') {
      form.contact = '';
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  return (
    <Dialog
      open={makePaymentOpen}
      onClose={handleMakePaymentClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Top Up {billing.organizationName}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-type-simple">
                Select Payment Type
              </InputLabel>
              <Select
                fullWidth
                value={form.type}
                onChange={onChange}
                name="type"
                inputProps={{
                  name: 'type',
                  id: 'outlined-type-simple',
                }}
              >
                {Object.entries(PAYMENT_TYPES).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    <em>{value.split('_').join(' ')}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="Amount"
              label="Amount in KES"
              name="amount"
              value={form.amount}
              onChange={onChange}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    <MoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              id="notes"
              label="Notes"
              name="notes"
              onChange={onChange}
              value={form.notes}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <NotesIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.sendReceipt}
                  onChange={(e) =>
                    setForm({ ...form, sendReceipt: e.target.checked })
                  }
                  name="sendReceipt"
                />
              }
              label="Send Receipt"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!form.amount.toString().length === 0 || loading}
          onClick={handleSubmit}
          color="primary"
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MakePaymentDialog;
