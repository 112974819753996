/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { getCurrentBilling } from '../../features/billing/billingActions';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PaymentList from './PaymentList';
import BillingMakePayment from './BillingMakePayment';
import { PAYMENT_TYPES_ORGANIZATION } from '../../features/payment/paymentTypes';
import { GET_BILLING } from '../../features/billing/billingTypes';
import { submitFlutterwavePayment } from '../../features/payment/paymentActions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // width: '100vw'
    marginTop: theme.spacing(11),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3, 2),
  },
}));

const BillingHome = ({ match, location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const billing = useSelector((state) => state.billing.current);
  const currentUser = useSelector((state) => state.auth.current);
  const organization = useSelector((state) => state.auth.organization);
  const payments = useSelector((state) => state.payment.payments);
  const notification = useSelector((state) => state.notification.notification);

  useEffect(() => {
    dispatch(getCurrentBilling());
    // eslint-disable-next-line
  }, []);

  const makePayment = (form) => {
    if (payments && organization) {
      FlutterwaveCheckout({
        public_key: 'FLWPUBK-e2851f021b6241f70bb10f546e7807af-X',
        tx_ref: `KYC-${organization.id}-${payments.count + 1}`,
        amount: form.amount,
        currency: 'KES',
        country: 'KE',
        payment_options:
          form.type === PAYMENT_TYPES_ORGANIZATION.MPESA ? 'mpesa' : 'card',
        customer: {
          email: currentUser.email,
          phone_number: form.mobileNumber,
          name: organization.name,
        },
        callback: function (data) {
          // specified callback function
          // console.log(data);
          data.type = form.type;
          dispatch(submitFlutterwavePayment(data));
        },
        customizations: {
          title: 'Identicheck',
          description: 'Identicheck',
          logo: 'https://storage.googleapis.com/soja-kyc.appspot.com/logo_transparent%404x.png',
        },
      });
    }
  };

  // Check for New Payment
  useEffect(() => {
    if (
      notification &&
      notification.type === 'success' &&
      notification.message === 'Payment Recorded successfully'
    ) {
      window.location.reload();
    }
  }, [notification]);

  // useEffect(()=>{
  //       console.log(currentUser)
  // },[currentUser])
  const [makePaymentOpen, setMakePaymentOpen] = useState(false);

  const handleMakePayment = (form) => {
    dispatch({ type: GET_BILLING, payload: null });
    setMakePaymentOpen(false);
    makePayment(form);
  };
  // const handleMakePaymentClose = () => {
  //   setMakePaymentOpen(false);
  // };
  if (!billing) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Paper style={{ padding: '1rem' }}>
          <Typography variant="body1" color="textSecondary">
            Billing Details
          </Typography>
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            {/* <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography color="primary" variant="h1">
                  <strong>{billing.creditAmount}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <strong>Verification Credits Available</strong>
                </Typography>
              </Grid>
            </Grid>
            */}
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <Typography variant="body1">
                  <strong>Wallet Balance</strong>
                </Typography>
                <Typography color="primary" variant="h1">
                  <strong>Kshs {billing.walletBalance}</strong>
                </Typography>
              </Grid>
              <Grid item xs>
                <Button
                  onClick={() => {
                    setMakePaymentOpen(true);
                  }}
                  size="large"
                  endIcon={<CardMembershipIcon />}
                  color="primary"
                  variant="contained"
                >
                  Fund Wallet
                </Button>
              </Grid>
            </Grid>
          </div>
          <Grid container style={{ marginTop: '0.5rem' }}>
            <Grid item xs={12}>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper style={{ marginTop: '1rem' }}>
          <PaymentList />
        </Paper>
        {billing && currentUser && (
          <BillingMakePayment
            makePaymentOpen={makePaymentOpen}
            handleMakePaymentClose={() => {
              console.log('Make Payment Close');
              setMakePaymentOpen(false);
            }}
            handleMakePayment={handleMakePayment}
          />
        )}
      </div>
    );
  }
};

export default BillingHome;
