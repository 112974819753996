import {
  GET_USER,
  GET_USERS,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER,
} from './userTypes';

const initialState = {
  users: null,
  current: null,
  loading: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        current: null,
      };
    case ADD_USER:
      return {
        ...state,
        // users: state.users.concat(action.payload), TODO: Fix Add User Immediate
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        // users:
        //  state.users.map(user =>
        //   user.id === action.payload.id ? action.payload : user
        // ),
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        loading: false,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    default:
      return state;
  }
};
