import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Settings from './Settings';
import ProfileView from './ProfileView';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROLE_SUPER_ADMIN } from '../../features/role/roleTypes';

const ProfileDashboard = ({ current, organization }) => {
  console.log(current);
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap-reverse' }}>
      <div style={{ flex: '1 1 auto', margin: 20, minWidth: 300 }}>
        <Card>
          <CardContent>
            <Typography variant="body2">
              <strong>User Settings</strong>
            </Typography>
            <Settings />
          </CardContent>
        </Card>
      </div>

      <div style={{ flex: '1 1 auto', margin: 20, minWidth: 300 }}>
        <Card>
          <CardContent>
            <Typography variant="body2">
              <strong>Profile</strong>
            </Typography>
            <ProfileView />
          </CardContent>
        </Card>
        {/* Organization if Admin */}
        {current &&
          organization &&
          (current.id === organization.userAdminId ||
            current.roleName === ROLE_SUPER_ADMIN) && (
            <div style={{ flex: '1 1 auto', marginTop: 5, minWidth: 300 }}>
              <Card>
                <CardContent>
                  <Button
                    component={Link}
                    to="/admin/profile/organization"
                    variant="outlined"
                  >
                    Organization Settings
                  </Button>
                </CardContent>
              </Card>
            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  current: state.auth.current,
  organization: state.auth.organization,
});

export default connect(mapStateToProps, {})(ProfileDashboard);
