export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENT = 'GET_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const FILTER_PAYMENT = 'FILTER_PAYMENT';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export const PAYMENT_TYPES = {
  CASH: 'CASH',
  MPESA: 'MPESA',
  ADMIN: 'ADMIN',
};

export const PAYMENT_TYPES_ORGANIZATION = {
  CARD: 'card',
  PAYPAL: 'paypal',
};
