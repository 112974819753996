import React from 'react';
import NumberFormat from 'react-number-format';

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

export const numberFormatText = (value, currency = '') => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator={true}
    prefix={currency + ` `}
    decimalSeparator="."
    decimalScale={2}
    fixedDecimalScale
    isNumericString
    allowNegative
    renderText={(value, props) => <div {...props}>{value}</div>}
  />
);

export const toTitleCase = (text) => {
  return text
    .toLowerCase()
    .split('_')
    .join(' ')
    .replace(/^(\w)|\s(\w)/g, (grp) => grp.toUpperCase());
};

// export const toTitleCase = (text) => {
//   return text
//     .toLowerCase()
//     .split('_')
//     .join(' ')
//     .replace(/^(\w)|\s(\w)/g, (grp) => grp.toUpperCase());
// };

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
