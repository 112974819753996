import * as React from 'react';
import toMaterialStyle from 'material-color-hash';
import ColorHash from 'color-hash';
import Topo from './Topo';
import Triangles from './Triangles';
import { connect } from 'react-redux';

const getInitials = settings =>
  (settings.firstName && settings.firstName.charAt(0).toUpperCase()) +
  (settings.lastName && settings.lastName.charAt(0).toUpperCase());

const colorHash = new ColorHash();

const getBgColorHash = seed => toMaterialStyle(seed, 800).backgroundColor;
const getFgColorHash = seed => toMaterialStyle(seed, 300).backgroundColor;

const getBackground = settings => {
  const seed = settings.userName;
  switch (settings.backgroundStyle) {
    case 'gradient':
      return `linear-gradient(to bottom right, ${getBgColorHash(
        seed
      )} 0%, ${getBgColorHash(seed + '-alt')} 100%)`;
    case 'solid':
    default:
      return getBgColorHash(seed);
  }
};

const ProfilePic = ({ current }) => {
  const [startX, startY, bgSize] = colorHash.rgb(
    current && current.userName + '-topo'
  );

  return current ? (
    <div
      id="bigProfilePic"
      style={{
        width: 150,
        height: 150,
        borderRadius: '100%',
        background: getBackground(current),
        margin: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {current.backgroundStyle === 'topo' && (
        <Topo
          fill={getFgColorHash(current.userName)}
          startX={startX}
          startY={startY}
          bgSize={bgSize * 2}
        />
      )}
      {current.backgroundStyle === 'triangles' && (
        <Triangles
          color1={getBackground(current)}
          color2={getBgColorHash(current.userName + '-alt')}
          startX={startX / 4}
          startY={startY / 4}
          bgSize={bgSize * 4}
        />
      )}
      <div
        style={{
          fontSize: 70,
          fontFamily: 'Roboto',
          color: '#fff',
          zIndex: 10
        }}
      >
        {getInitials(current)}
      </div>
    </div>
  ) : (
    <div>Loading</div>
  );
};

const mapStateToProps = state => ({
  current: state.auth.current
});
export default connect(mapStateToProps, {})(ProfilePic);
