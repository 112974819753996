import { GET_BILLINGS, GET_BILLING, UPDATE_BILLING } from './billingTypes';

const initialState = {
  billings: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BILLINGS:
      return {
        ...state,
        billings: action.payload,
      };
    case GET_BILLING:
      return {
        ...state,
        current: action.payload,
      };
    case UPDATE_BILLING:
      return {
        ...state,
        current: state.current ? action.payload : null,
        billings: {
          ...state.billings,
          data:
            state.billings && state.billings.data
              ? state.billings.data.map((billing) => {
                  return billing.id === action.payload.id
                    ? action.payload
                    : billing;
                })
              : state.data,
        },
      };

    default:
      return state;
  }
};
