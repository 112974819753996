export const countries = [
  {
    shortName: 'Kenya',
    name: 'Kenya 🇰🇪',
    code: 'KE',
    idTypes: ['NATIONAL_ID', 'PASSPORT', 'ALIEN_CARD'],
    paymentMethods: ['mpesa'],
    paymentCurrency: 'KES',
  },
  {
    shortName: 'Uganda',
    name: 'Uganda 🇺🇬',
    code: 'UG',
    idTypes: ['NATIONAL_ID_NO_PHOTO'],
    paymentMethods: [],
    paymentCurrency: 'UGS',
  },
  {
    shortName: 'Nigeria',
    name: 'Nigeria 🇳🇬',
    code: 'NG',
    paymentCurrency: 'NG',
    idTypes: ['BVN', 'NIN', 'NIN_SLIP', 'DRIVERS_LICENSE', 'VOTER_ID'],
    paymentMethods: [],
  },
  {
    shortName: 'Ghana',
    name: 'Ghana 🇬🇭',
    code: 'GH',
    paymentCurrency: 'GHS',
    idTypes: [
      'DRIVERS_LICENSE',
      'PASSPORT',
      'SSNIT',
      'VOTER_ID',
      'NEW_VOTER_ID',
    ],
    paymentMethods: ['mobilemoneyghana'],
  },
  {
    shortName: 'South Africa',
    name: 'South Africa 🇿🇦',
    code: 'ZA',
    idTypes: ['NATIONAL_ID', 'NATIONAL_ID_NO_PHOTO'],
    paymentMethods: [],
    paymentCurrency: 'ZAR',
  },
];
