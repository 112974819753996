import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  PAYMENT_ERROR,
  GET_PAYMENTS,
  GET_PAYMENT,
  DELETE_PAYMENT,
} from './paymentTypes';

const url = process.env.REACT_APP_API_URL + 'payments';

let notification;

// Submit Payment

export const submitPayment = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    notification = {
      message: `Payment Recorded successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Set Payment',
    });
  }
};

export const submitFlutterwavePayment = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(`${url}/flutterwave`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    notification = {
      message: `Payment Recorded successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Set Payment',
    });
  }
};

// Update Message

export const updatePayment = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Updated Payment successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Update Payment',
    });
  }
};

// Get Payments
export const getPayments = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const payments = res.data;
    dispatch({ type: GET_PAYMENTS, payload: payments });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Get Payments',
    });
  }
};

// Get Payment By ID
export const getPaymentByID = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const payment = res.data;
    dispatch({ type: GET_PAYMENT, payload: payment });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Get Payment',
    });
  }
};

// Delete Payment
export const deletePayment = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    const notification = {
      message: `Deleted Payment`,
      type: 'info',
    };

    dispatch({ type: DELETE_PAYMENT, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Delete Payment',
    });
  }
};
