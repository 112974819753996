import React, { useEffect, useState } from 'react';
import 'react-notion-x/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'rc-dropdown/assets/index.css';
import 'katex/dist/katex.min.css';
import { NotionRenderer } from 'react-notion-x';
import axios from 'axios';

const Help = () => {
  const [recordMap, setRecordMap] = useState(null);
  useEffect(() => {
    fetchNotionData();
    // eslint-disable-next-line
  }, []);

  const fetchNotionData = async () => {
    try {
      const contentData = await axios.get(
        process.env.REACT_APP_API_URL + 'documentation',
      );
      setRecordMap(contentData.data);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div>
      {recordMap && (
        <NotionRenderer
          recordMap={recordMap}
          fullPage={true}
          darkMode={localStorage.getItem('darkMode') ? true : false}
        />
      )}
    </div>
  );
};

export default Help;
