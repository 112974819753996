import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles, useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import SubjectIcon from '@mui/icons-material/Subject';
import { TiSortAlphabetically } from 'react-icons/ti';
import { useSelector, useDispatch } from 'react-redux';
import { addMessageTemplate } from '../../features/message-template/messageTemplateActions';
import { MESSAGE_TYPES } from '../../features/message-template/messageTemplateTypes';
import { getMessageGroups } from '../../features/message-group/messageGroupActions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { getOrganizations } from '../../features/organization/organizationActions';
import { ROLE_SUPER_ADMIN } from '../../features/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const AddMessageTemplate = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const messageGroups = useSelector(
    (state) => state.messageGroup.messageGroups,
  );
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const currentUser = useSelector((state) => state.auth.current);

  const page = useSelector((state) => state.page.page);

  const [form, setForm] = useState({
    name: '',
    sendSMS: false,
    sendEmail: true,
    content: '',
    subject: '',
    messageGroupId: null,
    type: MESSAGE_TYPES.NONE,
    typeDefault: false,
  });

  useEffect(() => {
    dispatch(getMessageGroups());
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    if (e.target.value !== 'None') {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Add Message Template') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/message-template');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.content = htmlContent;
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(addMessageTemplate(form));
    }
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    if (form) {
      generateSuggestions();
    }
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (editorState) {
      const html = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      ).replace(/(href=".*?")/g, '');

      setHtmlContent(html);
    }
  }, [editorState]);

  useEffect(() => {
    setForm({ ...form, content: '' });
    setEditorState(EditorState.createEmpty());
    // eslint-disable-next-line
  }, [userType]);

  // Suggestions

  const [suggestions, setSuggestions] = useState([]);

  const generateSuggestions = () => {
    let suggestions = [
      { text: 'First Name', value: `user.firstName` },
      { text: 'Last Name', value: `user.lastName` },
      { text: 'Mobile Number', value: `user.mobileNumber` },
      { text: 'Password Reset Link', value: `user.passwordResetLink` },
      { text: 'User Activate Link', value: `user.activateLink` },
    ];
    suggestions = suggestions.concat(getOptionalSuggestions());
    setSuggestions(suggestions);
  };

  const getOptionalSuggestions = () => {
    let optionalSuggestions = [];
    if (form.type === MESSAGE_TYPES.LOW_CREDITS) {
      optionalSuggestions = optionalSuggestions.concat(
        {
          text: 'Low Credit Level',
          value: 'billing.lowCreditLevel',
        },
        {
          text: 'Billing Credit Amount',
          value: 'billing.creditAmount',
        },
      );
    }
    return optionalSuggestions;
  };

  useEffect(() => {
    if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
      dispatch(getOrganizations());
    }
    // eslint-disable-next-line
  }, [currentUser]);

  if (!messageGroups) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={form.name}
                  name="name"
                  onChange={onChange}
                  id="name"
                  label="Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TiSortAlphabetically size={18} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.sendEmail}
                      onChange={(e) =>
                        setForm({ ...form, sendEmail: e.target.checked })
                      }
                      color="primary"
                    />
                  }
                  label="Send Email"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.sendSMS}
                      onChange={(e) =>
                        setForm({ ...form, sendSMS: e.target.checked })
                      }
                      color="primary"
                    />
                  }
                  label="Send SMS"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-message-type-simple">
                    Select Message Type
                  </InputLabel>

                  <Select
                    fullWidth
                    value={form.type || MESSAGE_TYPES.NONE}
                    onChange={onChange}
                    name="type"
                    inputProps={{
                      name: 'type',
                      id: 'outlined-message-type-simple',
                    }}
                  >
                    {Object.entries(MESSAGE_TYPES).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        <em>{value.split('_').join(' ')}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-message-group-simple">
                    Select Message Group
                  </InputLabel>
                  <Select
                    fullWidth
                    value={form.messageGroupId || 'None'}
                    onChange={onChange}
                    name="messageGroupId"
                    inputProps={{
                      name: 'messageGroupId',
                      id: 'outlined-message-group-simple',
                    }}
                  >
                    <MenuItem key={0} value="None">
                      <em>None</em>
                    </MenuItem>
                    {messageGroups &&
                      messageGroups.data.map((messageGroup) => (
                        <MenuItem key={messageGroup.id} value={messageGroup.id}>
                          <em>{messageGroup.name}</em>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={form.subject}
                  name="subject"
                  onChange={onChange}
                  id="subject"
                  label="Subject"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SubjectIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  onEditorStateChange={onEditorStateChange}
                  placeholder="Enter Message Content"
                  editorStyle={{
                    border: `1px solid ${theme.palette.primary.light}`,
                    padding: '1rem',
                    borderRadius: '5px',
                  }}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions,
                  }}
                  hashtag={{}}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              style={{ marginTop: '2rem' }}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !htmlContent ||
                !form.name ||
                !form.type ||
                !form.subject ||
                loading
              }
            >
              Add Message Template
            </Button>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default AddMessageTemplate;
