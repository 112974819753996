import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles, useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../../features/message/messageActions';
import { getUsers } from '../../../features/user/userActions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import '../../../App.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { getMessageGroups } from '../../../features/message-group/messageGroupActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const SendMessage = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const SEND_OPTION = {
    USER: 'Send to User',
    GROUP: 'Send to Group',
  };

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const users = useSelector((state) => state.user.users);
  const messageGroups = useSelector(
    (state) => state.messageGroup.messageGroups,
  );

  const [form, setForm] = useState({
    sendSMS: false,
    sendEmail: false,
    content: '',
    name: '',
    subject: '',
    messageTemplateId: null,
    userId: null,
    messageGroupId: null,
  });

  // Init Leases
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getMessageGroups());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      page &&
      page.includes('Send Message') &&
      notification &&
      notification.type === 'success'
    ) {
      history.push('/admin/messaging');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.content = htmlContent;
    dispatch(addMessage(form));
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const theme = useTheme();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const [sendOption, setSendOption] = useState(SEND_OPTION.VISITOR);

  useEffect(() => {
    generateSuggestions();
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (editorState) {
      setHtmlContent(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
    }
  }, [editorState]);

  const [suggestions, setSuggestions] = useState([]);

  const generateSuggestions = () => {
    setSuggestions([
      {
        text: 'First Name',
        value: `user.firstName`,
        url: `user.firstName`,
      },
      {
        text: 'Last Name',
        value: `user.lastName`,
        url: `user.lastName`,
      },
      {
        text: 'Mobile Number',
        value: `user.mobileNumber`,
        url: `user.mobileNumber`,
      },
      {
        text: 'Email',
        value: `user.email`,
        url: `user.email`,
      },
    ]);
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-option-simple">
                Select Send Option
              </InputLabel>
              <Select
                fullWidth
                value={sendOption}
                onChange={(e) => {
                  generateSuggestions();
                  setSendOption(e.target.value);
                }}
                name="sendOption"
                inputProps={{
                  name: 'sendOption',
                  id: 'outlined-option-simple',
                }}
              >
                {Object.entries(SEND_OPTION).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    <em>{value}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {sendOption === SEND_OPTION.USER && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-user-simple">
                  Select User
                </InputLabel>
                <Select
                  fullWidth
                  value={form.userId}
                  onChange={onChange}
                  name="userId"
                  inputProps={{
                    name: 'userId',
                    id: 'outlined-user-id-simple',
                  }}
                >
                  {users &&
                    users.data.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        <em>
                          {user.firstName} {user.lastName}
                        </em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {sendOption === SEND_OPTION.GROUP && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-message-groups-simple">
                  Select Message Groups
                </InputLabel>
                <Select
                  fullWidth
                  value={form.messageGroupId}
                  onChange={onChange}
                  name="messageGroupId"
                  inputProps={{
                    name: 'messageGroupId',
                    id: 'outlined-message-groups-simple',
                  }}
                >
                  {messageGroups &&
                    messageGroups.data.map((messageGroup) => (
                      <MenuItem key={messageGroup.id} value={messageGroup.id}>
                        <em>{messageGroup.name}</em>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={form.name}
              name="name"
              onChange={onChange}
              id="name"
              label="Name"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={form.subject}
              name="subject"
              onChange={onChange}
              id="subject"
              label="Subject"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.sendEmail}
                  onChange={(e) =>
                    setForm({ ...form, sendEmail: e.target.checked })
                  }
                  color="primary"
                />
              }
              label="Send Email"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.sendSMS}
                  onChange={(e) =>
                    setForm({ ...form, sendSMS: e.target.checked })
                  }
                  color="primary"
                />
              }
              label="Send SMS"
            />
          </Grid>

          <Grid item xs={12}>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              onEditorStateChange={onEditorStateChange}
              editorStyle={{
                border: `1px solid ${theme.palette.primary.light}`,
                padding: '1rem',
                borderRadius: '5px',
              }}
              mention={{
                separator: ' ',
                trigger: '@',
                suggestions,
              }}
              hashtag={{}}
            />
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={form.subject.length === 0 || loading}
            endIcon={<SendIcon size={32} />}
          >
            Send Message
          </Button>
          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SendMessage;
