import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from '../dashboard/Dashboard';
import Navbar from '../../components/layout/Navbar';
import TransactionList from '../transactions/TransactionList';
import { getCurrentUser } from '../../features/auth/authActions';
import { setCurrentPage } from '../../features/pages/pagesActions';
import AddUser from '../users/AddUser';
import Login from '../user/Login';
import LoginMobile from '../user/LoginMobile';
import SignUp from '../user/SignUp';
import ForgotPassword from '../user/ForgotPassword';
import ResetPassword from '../user/ResetPassword';
import BillingList from '../billing/BillingList';
import OrganizationSettings from '../profile/OrganizationSettings';
import ProfileDashboard from '../profile/ProfileDashboard';
import * as qs from 'querystring';
import VerifyUser from '../transactions/VerifyUser';
import BillingHome from '../billing/BillingHome';
import OrganizationList from '../organization/OrganizationList';
import AddOrganization from '../organization/AddOrganization';
import OrganizationView from '../organization/view/OrganizationView';
import EditOrganization from '../organization/EditOrganization';
import UserList from '../users/UserList';
import EditUser from '../users/EditUser';
import UserView from '../users/view/UserView';
import TransactionView from '../transactions/view/TransactionView';
import MessageTemplateList from '../message-template/MessageTemplateList';
import AddMessageTemplate from '../message-template/AddMessageTemplate';
import EditMessageTemplate from '../message-template/EditMessageTemplate';
import MessageTemplateView from '../message-template/view/MessageTemplateView';
import SendMessage from '../messaging/create/SendMessage';
import MessageList from '../messaging/MessageList';
import BillingView from '../billing/view/BillingView';
import ActivateUser from '../user/ActivateUser';
import Help from '../help/Help';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    marginTop: '4rem',
  },
  notification: {
    position: 'fixed',
    top: 0,
    left: 0,
  },
  container: {
    marginTop: '4rem',
    flex: 1,
  },
}));

const Home = ({ location, history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const current = useSelector((state) => state.auth.current);
  // const organization = useSelector((state) => state.auth.organization);

  useEffect(() => {
    // Listen for Route Changes

    const { pathname } = location;
    // console.log('New path:', pathname.split('/').pop());
    // console.log('New path:', location);

    dispatch(setCurrentPage(pathname));
    // Listen for Auth
    if (localStorage.getItem('token') == null) {
      // if (!pathname.includes('/main/auth')) {
      //   history.push('/main/auth');
      // }
      // history.push('/signin');
    } else {
      if (!current) {
        dispatch(getCurrentUser());
      }
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // Listen for Route Changes

    const { pathname } = location;
    // console.log('New path:', pathname.split('/').pop());
    // console.log('New path:', location);

    dispatch(setCurrentPage(pathname));
    const params = qs.parse(location.search.replace('?', ''));
    if (params && params.action && params.action.includes('reset-password')) {
      return;
    }
    if (pathname.includes('forgot-password')) {
      return;
    }
    // Listen for Auth
    if (localStorage.getItem('token') == null) {
      // console.log('No token');
      if (!pathname.includes('/')) {
        history.push('/');
      }
    } else {
      if (!current) {
        dispatch(getCurrentUser());
      }
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    const params = qs.parse(location.search.replace('?', ''));
    if (params && params.action && params.action.includes('reset-password')) {
      return;
    }
    const { pathname } = location;

    if (pathname.includes('forgot-password')) {
      return;
    } else if (pathname.includes('reset-password')) {
      return;
    } else if (location.pathname.includes('activate')) {
      return;
    }

    if (!current && localStorage.getItem('token') == null) {
      history.push('/');
    } else {
      // if (location.pathname.includes('')) {
      //   history.push('/admin');
      // }
    }
    // eslint-disable-next-line
  }, [current]);

  return (
    <div className={classes.root}>
      <Route
        path="(^)"
        render={() => (
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/mobile" component={LoginMobile} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/activate" component={ActivateUser} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
          </Switch>
        )}
      />

      <Route
        path="(^/admin)"
        render={() => (
          <Fragment>
            <Navbar />
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.container}>
                  <Switch>
                    <Route path="/admin" exact component={Dashboard} />
                    <Route
                      path="/admin/transactions"
                      exact
                      component={TransactionList}
                    />

                    <Route path="/admin/verify" component={VerifyUser} />

                    <Route path="/admin/customers/add" component={AddUser} />

                    <Route
                      exact
                      path="/admin/billing/view/:id"
                      component={BillingView}
                    />

                    <Route
                      exact
                      path="/admin/billing"
                      component={BillingList}
                    />

                    <Route
                      exact
                      path="/admin/billing/organization"
                      component={BillingHome}
                    />

                    <Route
                      exact
                      path="/admin/profile"
                      component={ProfileDashboard}
                    />
                    <Route
                      exact
                      path="/admin/profile/organization"
                      component={OrganizationSettings}
                    />

                    <Route
                      exact
                      path="/admin/organizations"
                      component={OrganizationList}
                    />
                    <Route
                      exact
                      path="/admin/organizations/add"
                      component={AddOrganization}
                    />
                    <Route
                      exact
                      path="/admin/organizations/view/:id"
                      component={OrganizationView}
                    />
                    <Route
                      exact
                      path="/admin/organizations/edit/:id"
                      component={EditOrganization}
                    />

                    <Route exact path="/admin/users" component={UserList} />
                    <Route exact path="/admin/users/add" component={AddUser} />
                    <Route
                      exact
                      path="/admin/users/edit/:id"
                      component={EditUser}
                    />
                    <Route
                      exact
                      path="/admin/users/view/:id"
                      component={UserView}
                    />
                    <Route
                      exact
                      path="/admin/users/view/:id/admin/:organizationId"
                      component={UserView}
                    />
                    <Route
                      exact
                      path="/admin/transactions"
                      component={TransactionList}
                    />
                    <Route
                      exact
                      path="/admin/transactions/view/:id"
                      component={TransactionView}
                    />

                    <Route
                      exact
                      path="/admin/transactions/view/:id/admin/:organizationId"
                      component={TransactionView}
                    />

                    <Route
                      exact
                      path="/admin/message-template"
                      component={MessageTemplateList}
                    />

                    <Route
                      exact
                      path="/admin/message-template/add"
                      component={AddMessageTemplate}
                    />
                    <Route
                      exact
                      path="/admin/message-template/edit/:id"
                      component={EditMessageTemplate}
                    />
                    <Route
                      exact
                      path="/admin/message-template/view/:id"
                      component={MessageTemplateView}
                    />

                    <Route
                      exact
                      path="/admin/messaging/send"
                      component={SendMessage}
                    />

                    <Route
                      exact
                      path="/admin/messaging"
                      component={MessageList}
                    />
                    <Route exact path="/admin/help" component={Help} />
                  </Switch>
                </div>
              </Grid>
            </Grid>
          </Fragment>
        )}
      />
    </div>
  );
};

export default Home;
