import { Link as RouterLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { login, getCurrentUser } from '../../features/auth/authActions';
import PropTypes from 'prop-types';
import bg from '../../assets/bg.png';
import setAuthToken from '../../utils/setAuthToken';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../utils/colors';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://identigate.co.ke/">
        Identigate Integrated Solutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: SECONDARY_COLOR,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const Login = ({ login, current, history }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password });
  };

  useEffect(() => {
    if (current) {
      history.push('/admin');
    } else if (localStorage.getItem('token') != null) {
      getCurrentUser();
    }
    // eslint-disable-next-line
  }, [current]);
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <div className={classes.contentBox}>
          <Paper style={{ padding: '5rem' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <img src="/logo_transparent.svg" width="100" alt="Logo" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2">
                  <strong style={{ color: PRIMARY_COLOR }}>
                    Identi
                    <span style={{ color: SECONDARY_COLOR }}>Check</span>
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ marginTop: '2rem', padding: '1rem' }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2">
                <strong>
                  Soja KYC is now{' '}
                  <a
                    target="__blank"
                    style={{
                      textDecoration: 'none',
                      color: SECONDARY_COLOR,
                    }}
                    href="https://identicheck.app"
                  >
                    Identicheck
                  </a>{' '}
                  !
                </strong>
              </Typography>
            </Grid>
          </Paper>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  // autoComplete="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: '2rem' }}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: PRIMARY_COLOR, color: '#FFFFFF' }}
                  className={classes.submit}
                  size="large"
                >
                  Sign In
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  style={{ marginTop: '1rem' }}
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <Button
                      component={RouterLink}
                      variant="outlined"
                      to="/forgot-password"
                    >
                      Forgot password?
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  current: state.auth.current,
});
export default connect(mapStateToProps, { login, getCurrentUser })(Login);
