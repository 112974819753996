import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles, useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { countries } from '../../utils/countries';
import SearchIcon from '@mui/icons-material/Search';
import { GoVerified } from 'react-icons/go';
import LinearProgress from '@mui/material/LinearProgress';
import { makeTransaction } from '../../features/transaction/transactionActions';
import { toTitleCase } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const VerifyUser = ({ history }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  // const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const organization = useSelector((state) => state.auth.organization);
  const transaction = useSelector((state) => state.transaction.current);

  const theme = useTheme();

  const [orgCountries, setOrgCountries] = useState(null);

  useEffect(() => {
    if (organization) {
      console.log(organization);

      const allowedCountries = [];
      for (const country of countries) {
        if (organization.countries.includes(country.shortName)) {
          allowedCountries.push(country);
        }
      }

      setOrgCountries(allowedCountries);
    }
    // eslint-idsable-next-line
  }, [organization]);

  const [country, setCountry] = useState(countries[0]);

  const [form, setForm] = useState({
    countryCode: null,
    idType: null,
    idNumber: null,
  });

  const inputLabel = React.useRef(null);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (transaction) {
      history.push(`/admin/transactions/view/${transaction.id}`);
    }
    // eslint-disable-next-line
  }, [transaction]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(makeTransaction(form));
  };
  return (
    <Paper className={classes.root}>
      {/* Navigation */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Avatar
          style={{
            width: '10rem',
            height: '10rem',
            backgroundColor: theme.palette.primary.main,
          }}
          className={classes.green}
        >
          <GoVerified size={50} />
        </Avatar>
        <Typography variant="h4">
          <strong>Verify User</strong>{' '}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          Enter User Details to Get Full ID Details
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel ref={inputLabel} htmlFor="outlined-country-simple">
                Select Country
              </InputLabel>
              <Select
                onChange={(e) => {
                  const country = orgCountries.find(
                    (country) => country.name === e.target.value,
                  );
                  if (!country) {
                    return;
                  }
                  setCountry(country);
                  setForm({ ...form, countryCode: country.code });
                }}
                name="countryCode"
                fullWidth
                inputProps={{
                  name: 'countryCode',
                  id: 'outlined-country-simple',
                }}
              >
                {orgCountries &&
                  orgCountries.map((country, idx) => {
                    return (
                      <MenuItem key={idx} value={country.name}>
                        <em>{country.name}</em>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>

          {country && (
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel ref={inputLabel} htmlFor="outlined-id-type-simple">
                  Select ID Type
                </InputLabel>
                <Select
                  onChange={onChange}
                  name="idType"
                  fullWidth
                  inputProps={{
                    name: 'idType',
                    id: 'outlined-id-type-simple',
                  }}
                >
                  {country.idTypes.map((idType, idx) => {
                    return (
                      <MenuItem key={idx} value={idType}>
                        <em>{toTitleCase(idType)}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {form.idType && (
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                name="idNumber"
                variant="outlined"
                required
                fullWidth
                type="text"
                onChange={onChange}
                helperText={'Enter ID Number'}
                label="ID Number"
                autoFocus
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              size="large"
              endIcon={<SearchIcon />}
              disabled={
                !form.countryCode || !form.idNumber || !form.idType || loading
              }
            >
              Search
            </Button>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress color="primary" />
            </Grid>
          )}
        </Grid>
      </form>
    </Paper>
  );
};

export default VerifyUser;
