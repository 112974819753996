import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import notificationReducer from './notification/notificationReducer';
import pagesReducer from './pages/pagesReducer';
import roleReducer from './role/roleReducer';
import organizationReducer from './organization/organizationReducer';
import userReducer from './user/userReducer';
import transactionReducer from './transaction/transactionReducer';
import messageTemplateReducer from './message-template/messageTemplateReducer';
import messageReducer from './message/messageReducer';
import messageGroupReducer from './message-group/messageGroupReducer';
import billingReducer from './billing/billingReducer';
import paymentReducer from './payment/paymentReducer';

export default combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  page: pagesReducer,
  payment:paymentReducer,
  transaction:transactionReducer,
  role: roleReducer,
  user:userReducer,
  organization: organizationReducer,
  messageTemplate:messageTemplateReducer,
  message:messageReducer,
  billing:billingReducer,
  messageGroup:messageGroupReducer
});
