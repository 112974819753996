import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
import ReactCountryFlag from 'react-country-flag';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import {
  clearTransaction,
  getTransactionAdminById,
  getTransactionById,
} from '../../../features/transaction/transactionActions';
import { format, parseISO } from 'date-fns';
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import { toTitleCase } from '../../../utils/functions';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { ROLE_SUPER_ADMIN } from '../../../features/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3, 2),
  },
  contentBox: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  badge: {
    padding: theme.spacing(1),
    borderRadius: '5px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
}));

const TransactionView = ({ history, match }) => {
  const classes = useStyles();

  const transaction = useSelector((state) => state.transaction.current);
  const currentUser = useSelector((state) => state.auth.current);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser && match && match.params.id) {
      if (currentUser.roleName === ROLE_SUPER_ADMIN) {
        dispatch(
          getTransactionAdminById(match.params.id, match.params.organizationId),
        );
      } else {
        dispatch(getTransactionById(match.params.id));
      }
    }
    // eslint-disable-next-line
  }, [match, currentUser]);

  const goBack = () => {
    dispatch(clearTransaction());
    history.goBack();
  };

  const printDocument = () => {
    //const input = document.getElementById('divToPrint');

    //get table html
    const pdfTable = document.getElementById('print');
    //html to pdf format
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  };

  if (!transaction) {
    return (
      <div className={classes.root}>
        <LinearProgress color="primary" />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Paper style={{ padding: '1rem' }}>
          <Grid container>
            <Grid item xs={2}>
              <Button
                edge="start"
                color="inherit"
                onClick={() => {
                  goBack();
                }}
                aria-label="back"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                edge="start"
                color="inherit"
                onClick={() => {
                  printDocument();
                }}
                aria-label="back"
                variant="outlined"
                startIcon={<PrintIcon />}
              >
                PRINT
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Grid id="print" container spacing={4}>
          <Grid item xs={12} md={4}>
            <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
              <Typography variant="body1" color="textSecondary">
                Results Overview
              </Typography>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={2}
                style={{ marginTop: '1rem' }}
              >
                {/* Transaction ID */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Transaction ID:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography className={classes.badge} variant="button">
                    <strong>#{transaction.id}</strong>
                  </Typography>
                </Grid>
                {/* Date */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Date:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography
                    label={transaction.createdAt}
                    variant="body1"
                    color="primary"
                  >
                    {format(
                      parseISO(transaction.createdAt, 'yyyy-MM-dd', new Date()),
                      'dd-MM-yyyy p',
                    )}
                  </Typography>
                </Grid>
                {/* Duration */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Duration:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography
                    label={`${parseInt(transaction.duration / 1000)}s`}
                    variant="body1"
                    color="primary"
                  >
                    {parseInt(transaction.duration / 1000)}s
                  </Typography>
                </Grid>
                {/* Result */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Result:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography
                    style={{
                      background:
                        transaction.result === 'Approved'
                          ? green[100]
                          : red[100],
                      color:
                        transaction.result === 'Approved'
                          ? green[800]
                          : red[800],
                      padding: '0.5rem',
                      borderRadius: '5px',
                      width: 'fit-content',
                    }}
                    variant="body1"
                    color="primary"
                  >
                    <strong>
                      {transaction.result === 'Approved'
                        ? 'Approved'
                        : 'Rejected'}
                    </strong>
                  </Typography>
                </Grid>
                {/* Message */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Message:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body1">{transaction.message}</Typography>
                </Grid>
                {/* Verified By */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Verified By:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Chip
                    style={{ cursor: 'pointer' }}
                    component={Link}
                    to={`/admin/users/view/${transaction.userId}`}
                    label={transaction.userName}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
              <Grid container spacing={4} alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    Results
                  </Typography>
                </Grid>
                {/* Image */}
                <Grid item xs={12} md={4}>
                  {/* Delay rendering until element is visible (lazy rendering) */}
                  <VisibilitySensor>
                    <Img
                      style={{ maxWidth: '15rem', border: '10px' }}
                      width={300}
                      // onClick={() => setImagePickerOpen(true)}
                      src={
                        transaction.idRecord.documentImage &&
                        !transaction.idRecord.documentImage.includes(
                          'Not Available',
                        )
                          ? `data:image/jpeg;base64,${transaction.idRecord.documentImage}`
                          : [
                              'https://placehold.co/600x800/DEDEDE/FFFFFF/png?text=No+ID+Image',
                            ]
                      }
                    />
                  </VisibilitySensor>
                </Grid>
                {/* Content */}
                <Grid item xs={12} md={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h4">
                        <strong>{transaction.idRecord.fullName}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {/* Gender */}
                      <div className={classes.contentBox}>
                        <Typography variant="body1">
                          <strong>Gender : </strong>
                        </Typography>
                        <div className={classes.contentBox}>
                          <Typography variant="body2">
                            {transaction.idRecord.gender}
                          </Typography>
                        </div>
                      </div>
                      {/* ID Number */}
                      <div className={classes.contentBox}>
                        <Typography variant="body1">
                          <strong>ID Number : </strong>
                        </Typography>
                        <div className={classes.flexRow}>
                          <Typography
                            variant="body2"
                            style={{ marginRight: '1rem' }}
                          >
                            {transaction.idRecord.idNumber}
                          </Typography>
                        </div>
                      </div>
                      {/* Serial Number */}
                      {transaction.idRecord.serialNo && (
                        <div className={classes.contentBox}>
                          <Typography variant="body1">
                            <strong>Serial Number : </strong>
                          </Typography>
                          <div className={classes.flexRow}>
                            <Typography
                              variant="body2"
                              style={{ marginRight: '1rem' }}
                            >
                              {transaction.idRecord.serialNumber}
                            </Typography>
                          </div>
                        </div>
                      )}

                      {/* Document Type */}
                      <div className={classes.contentBox}>
                        <Typography variant="body1">
                          <strong>Document Type : </strong>
                        </Typography>
                        <div className={classes.flexRow}>
                          <Typography
                            variant="body2"
                            style={{ marginRight: '1rem' }}
                          >
                            {transaction.idRecord.documentType}
                          </Typography>
                        </div>
                      </div>

                      {/* Nationality */}
                      <div className={classes.contentBox}>
                        <Typography variant="body1">
                          <strong>Nationality : </strong>
                        </Typography>
                        <div className={classes.flexRow}>
                          <Typography
                            variant="body2"
                            style={{ marginRight: '1rem' }}
                          >
                            {transaction.idRecord.nationality}
                          </Typography>
                          <ReactCountryFlag
                            styleProps={{
                              width: '30px',
                              height: '30px',
                              borderRadius: '2px',
                            }}
                            code={
                              transaction.idRecord.nationCode === 'GH'
                                ? 'gh'
                                : 'ke'
                            }
                            svg
                          />
                        </div>
                      </div>

                      {/* Age */}
                      {transaction.idRecord.dateOfBirth && (
                        <div className={classes.contentBox}>
                          <Typography variant="body1">
                            <strong>Date Of Birth : </strong>
                          </Typography>
                          <div className={classes.flexRow}>
                            <Typography
                              variant="body2"
                              style={{ marginRight: '1rem' }}
                            >
                              {transaction.idRecord.dateOfBirth}
                            </Typography>
                          </div>
                        </div>
                      )}

                      {/* Place Of Birth */}
                      {transaction.idRecord.placeOfBirth && (
                        <div className={classes.contentBox}>
                          <Typography variant="body1">
                            <strong>Place of Birth : </strong>
                          </Typography>
                          <div className={classes.flexRow}>
                            <Typography
                              variant="body2"
                              style={{ marginRight: '1rem' }}
                            >
                              {toTitleCase(transaction.idRecord.placeOfBirth)}
                            </Typography>
                          </div>
                        </div>
                      )}

                      {/* Place Of Live */}
                      {transaction.idRecord.placeOfLive && (
                        <div className={classes.contentBox}>
                          <Typography variant="body1">
                            <strong>Residence(At Registration) : </strong>
                          </Typography>
                          <div className={classes.flexRow}>
                            <Typography variant="body2">
                              {toTitleCase(transaction.idRecord.placeOfLive)}
                            </Typography>
                          </div>
                        </div>
                      )}
                      {/* Date of Issue */}
                      {transaction.idRecord.dateOfIssue && (
                        <div className={classes.contentBox}>
                          <Typography variant="body1">
                            <strong>Date Of Issue : </strong>
                          </Typography>
                          <div className={classes.flexRow}>
                            <Typography variant="body2">
                              {transaction.idRecord.dateOfIssue}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default TransactionView;
