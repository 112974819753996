import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getMessages } from '../../features/message/messageActions';
import { Link } from 'react-router-dom';
import CustomSearchRender from '../../utils/CustomSearchRender';
import { format, parseISO } from 'date-fns/esm';
import SendIcon from '@mui/icons-material/Send';
import ForumIcon from '@mui/icons-material/Forum';
import { ROLE_SUPER_ADMIN } from '../../features/role/roleTypes';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MessageList = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.message.messages);
  const currentUser = useSelector((state) => state.auth.current);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return { name, direction };
  };
  const columns = [
    {
      name: 'organizationId',
      label: 'Organization ID',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'organizationName',
      label: 'Organization',
      options: {
        display: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        sortOrder: getSortOrder('organizationName'),
        filter: false,
        viewColumns: false,
        print: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value}
              component={Link}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
      },
    },
    {
      name: 'sendSMS',
      label: 'SMS',
      options: {
        display: true,
        sortOrder: getSortOrder('sendSMS'),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <DoneIcon /> : <div></div>;
        },
      },
    },
    {
      name: 'sendEmail',
      label: 'Email',
      options: {
        display: true,
        sortOrder: getSortOrder('sendEmail'),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <DoneIcon /> : <div></div>;
        },
      },
    },
    {
      name: 'subject',
      label: 'subject',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
      },
    },
    {
      name: 'recipientType',
      label: 'Receipient Type',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
      },
    },
    {
      name: 'recipientName',
      label: 'Recipient',
      options: {
        display: true,
        sort: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Sent At',
      options: {
        display: true,
        sortOrder: getSortOrder('createdAt'),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'pp dd-MM-yyyy',
              )}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    downloadOptions: {
      filename: 'message-list.csv',
      useDisplayedRowsOnly: false,
    },
    serverSide: true,
    count: messages && messages.count,
    onTableChange: (action, tableState) => {
      // TODO : See On Table Change
      // console.log(`Action ${action}`);
      // console.log(`Table State ${JSON.stringify(tableState)}`);
      // this.xhrRequest('my.api.com/tableData', result => {
      //   this.setState({ data: result });
      // });
    },
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage });
    },
    rowHover: false,
    rowsPerPageOptions: [1, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction,
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getMessages(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (messages) {
      var finalData =
        messages.data &&
        messages.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [messages]);

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            component={Link}
            to="/admin/messaging/send"
            className={classes.button}
            startIcon={<SendIcon />}
          >
            Send Message
          </Button>
          <Button
            variant="outlined"
            size="large"
            component={Link}
            style={{ cursor: 'pointer' }}
            to={`/admin/message-group`}
            className={classes.button}
            startIcon={<ForumIcon />}
          >
            Message Groups
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Message List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MessageList;
