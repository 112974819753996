import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Hidden from '@mui/material/Hidden';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { GoVerified } from 'react-icons/go';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';

import {
  ROLE_ADMIN,
  ROLE_MANAGEMENT,
  ROLE_SUPER_ADMIN,
} from '../../features/role/roleTypes';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
}));

const Sidebar = ({ selected, mobileOpen, closeDrawer }) => {
  const classes = useStyles();

  const handleDrawerToggle = () => {
    closeDrawer();
  };

  const theme = useTheme();

  const current = useSelector((state) => state.auth.current);

  // const [isExpandable, setIsExpandable] = useState(false);
  // const [open, setOpen] = useState(false);

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbar} />
      <List>
        <ListItem
          component={Link}
          to="/admin"
          selected={selected === 'Dashboard'}
          button
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        {current && current.roleName === ROLE_SUPER_ADMIN && (
          <ListItem
            component={Link}
            to="/admin/organizations"
            selected={selected === 'Organizations'}
            button
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Organizations" />
          </ListItem>
        )}
        {current &&
          (current.roleName === ROLE_ADMIN ||
            current.roleName === ROLE_SUPER_ADMIN) && (
            <ListItem
              component={Link}
              to="/admin/users"
              selected={selected === 'Users'}
              button
            >
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          )}

        {current &&
          (current.roleName === ROLE_ADMIN ||
            current.roleName === ROLE_MANAGEMENT ||
            current.roleName === ROLE_SUPER_ADMIN) && (
            <ListItem
              component={Link}
              to="/admin/transactions"
              selected={selected === 'Transactions'}
              button
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
            </ListItem>
          )}

        <ListItem
          component={Link}
          to="/admin/verify"
          selected={selected === 'Verify'}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
          button
        >
          <ListItemIcon>
            <GoVerified size={24} color={theme.palette.primary.contrastText} />
          </ListItemIcon>
          <ListItemText primary="Verify" />
        </ListItem>

        <ListItem
          component={Link}
          to={
            current && current.roleName === ROLE_SUPER_ADMIN
              ? `/admin/billing`
              : `/admin/billing/organization`
          }
          selected={selected === `Billing`}
          button
        >
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary={`Billing`} />
        </ListItem>
        {current && current.roleName === ROLE_SUPER_ADMIN && (
          <ListItem
            component={Link}
            to="/admin/message-template"
            selected={selected === 'Message Templates'}
            button
          >
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary="Message Templates" />
          </ListItem>
        )}

        {current && current.roleName === ROLE_SUPER_ADMIN && (
          <ListItem
            component={Link}
            to="/admin/messaging"
            selected={selected === 'Messaging'}
            button
          >
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary="Messaging" />
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
  return (
    <div>
      <Hidden smUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Sidebar;
