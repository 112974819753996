import {
  GET_MESSAGE,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  UPDATE_MESSAGE,
  GET_MESSAGES,
} from './messageTypes';

const initialState = {
  messages: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };

    case ADD_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: 
          state.messages && state.messages.data.length>0 ?
          state.messages.data.concat(action.payload)
          :[action.payload],
          count:state.count+1
        },
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          data:
          state.messages && state.messages.data.length>0 ?
          state.messages.data.filter(
          (message) => message.id !== action.payload,
        ):state.messages.data
      }
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        messages:{
        ...state.messages,
        data:
        state.messages && state.messages.data.length>0?
          state.messages.data.map(
            (message) =>
              (message.id = action.payload.id
                ? action.payload
                : message),
          ):
          state.messages.data
        }
      };
    case GET_MESSAGE:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};
