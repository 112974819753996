import {
  GET_USER,
  GET_USERS,
  UPDATE_USER,
  ADD_USER,
  USER_ERROR,
} from './userTypes';
import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import { clearLoading } from '../notification/notificationActions';

const url = process.env.REACT_APP_API_URL;

// Get Users
export const getUsers = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const res = await axios.get(`${url}users`, {
      params: filter,
    });
    const users = res.data;
    dispatch({ type: GET_USERS, payload: users });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    dispatch({ type: CLEAR_LOADING });
    console.error(e);
  }
};

// Get User By ID for Super Admin
export const getUserByIdAdmin = (id, organizationId) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}users/${id}/admin/${organizationId}`);
    const user = res.data;
    dispatch({ type: GET_USER, payload: user });
  } catch (e) {
    clearLoading();
    console.error(e);
  }
};

// Get User By ID
export const getUserById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}users/${id}`);
    const user = res.data;
    dispatch({ type: GET_USER, payload: user });
  } catch (e) {
    clearLoading();
    console.error(e);
  }
};

// Create User
export const addUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}users`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_USER, payload: res.data });
    // Set Register Success Notification

    const user = res.data;
    const notification = {
      message: `Added User:  ${user.firstName} successfully`,
      type: 'success',
    };
    console.log(user);
    dispatch({ type: SET_NOTIFICATION, payload: notification });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Add User' });
  }
};

// Create User
export const addUserAdmin = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}users/admin`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_USER, payload: res.data });
    // Set Register Success Notification

    const user = res.data;
    const notification = {
      message: `Added User:  ${user.firstName} successfully`,
      type: 'success',
    };
    // console.log(user);
    dispatch({ type: SET_NOTIFICATION, payload: notification });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Add User' });
  }
};

export const addUsers = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(`${url}users/bulk`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const notification = {
      message: `Added ${data.length} Users successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Add User' });
  }
};

// Update User
export const updateSelectedUser = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}users/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_USER, payload: res.data });
    // Set Register Success Notification

    const user = res.data;
    const notification = {
      message: `Updated User:  ${user.firstName} successfully`,
      type: 'success',
    };
    console.log(user);
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Update User' });
  }
};

// Delete User

// Make Org Admin
export const makeUserOrgAdmin = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}users/${id}/org_admin`);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_USER, payload: res.data });
    // Set Make User Org Admin Success Notification

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Make Org Admin' });
  }
};

// ResentActivation Mail
export const resendActivationMail = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}users/activation_email`, { id });

    dispatch({ type: CLEAR_LOADING });
    // Set Resend Activation Mail

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Resend Activation Mail' });
  }
};

// Update User
export const updateUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}auth`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_USER, payload: res.data });
    // Set Register Success Notification

    const user = res.data;
    const notification = {
      message: `Updated User:  ${user.firstName} successfully`,
      type: 'success',
    };
    console.log(user);
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Update User' });
  }
};

// Update User Admin
export const updateUserAdmin = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}users/${id}/admin`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_USER, payload: res.data });
    // Set Register Success Notification

    const user = res.data;
    const notification = {
      message: `Updated User:  ${user.firstName} successfully`,
      type: 'success',
    };
    console.log(user);
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Update User' });
  }
};

// Update User Organization
export const updateUserOrganization =
  (id, organizationId) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      const res = await axios.post(`${url}users/${id}/organization`, {
        organizationId,
      });

      dispatch({ type: CLEAR_LOADING });
      // dispatch({ type: UPDATE_USER, payload: res.data });
      // Set Register Success Notification

      const notification = {
        message: res.data.message,
        type: 'success',
      };
      console.log(res);
      dispatch({ type: SET_NOTIFICATION, payload: notification });

      setTimeout(() => {
        dispatch({ type: CLEAR_NOTIFICATION });
      }, 2000);
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error);
      dispatch({ type: USER_ERROR, payload: 'Failed to Update User' });
    }
  };

// Update User Status
export const updateUserStatus = (id, isEnabled) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}users/${id}/status`, {
      isEnabled,
    });

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_USER, payload: res.data });
    // Set Register Success Notification

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    // console.log(res);
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Update User' });
  }
};

// Update User Role
export const updateUserRole = (id, roleId) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}users/${id}/role`, {
      roleId,
    });

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_USER, payload: res.data });
    // Set Register Success Notification

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    console.log(res);
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Update User' });
  }
};

// Reset User Password
export const resetUserPassword = (id, roleId) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}users/${id}/reset-password`, {
      roleId,
    });

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_USER, payload: res.data });
    // Set Register Success Notification

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: USER_ERROR, payload: 'Failed to Update User' });
  }
};
