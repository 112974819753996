import {
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  SUBMIT_TRANSACTION,
  GET_TRANSACTION_STATS,
  CLEAR_TRANSACTION
} from './transactionTypes';

const initialState = {
  transactions: null,
  current: null,
  loading: null,
  error: null,
  stats:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
        loading: false
      };
    case CLEAR_TRANSACTION:
      return{
        ...state,
        current:null,
        loading:false
      }  
    case SUBMIT_TRANSACTION:
      return {
        ...state,
        current:action.payload,
        transactions: {...state.transactions,
          data:
          state.transactions && state.transactions.data.length>0?
          state.transactions.data.concat(action.payload):[],
          count:
          state.transactions?state.transactions.count+1:1
        },
        loading: false
      };
    case GET_TRANSACTION:
      return {
        ...state,
        current: action.payload,
        loading: false
      };
    case GET_TRANSACTION_STATS:
      return {
        ...state,
        stats: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
