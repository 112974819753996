import * as React from 'react';
import Typography from '@mui/material/Typography';
import ProfilePic from './ProfilePic';
import { connect } from 'react-redux';

const ProfileView = ({ current }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ProfilePic />
    {current && (
      <Typography variant="body2">
        {current.firstName} {current.lastName}
      </Typography>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  current: state.auth.current,
});
export default connect(mapStateToProps, {})(ProfileView);
