import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getMessageTemplates } from '../../features/message-template/messageTemplateActions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Link } from 'react-router-dom';
import CustomSearchRender from '../../utils/CustomSearchRender';
import { format, parseISO } from 'date-fns/esm';
import { Chip } from '@mui/material';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import DoneIcon from '@mui/icons-material/Done';
import { ROLE_SUPER_ADMIN } from '../../features/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MessageTemplateList = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();
  const messageTemplates = useSelector(
    (state) => state.messageTemplate.messageTemplates,
  );
  const currentUser = useSelector((state) => state.auth.current);
  // const notification = useSelector((state) => state.notification.notification);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        console.log(filter.sortOrder);
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'organizationId',
      label: 'Organization ID',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'organizationName',
      label: 'Organization',
      options: {
        display: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        sortOrder: getSortOrder('organizationName'),
        filter: false,
        viewColumns: false,
        print: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value}
              component={Link}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sortOrder: getSortOrder('name'),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },

    {
      name: 'content',
      label: 'Content',
      options: {
        display: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        display: true,
        sortOrder: getSortOrder('type'),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'subject',
      label: 'Subject',
      options: {
        display: false,
      },
    },
    {
      name: 'sendSMS',
      label: 'SMS',
      options: {
        display: true,
        sortOrder: getSortOrder('sendSMS'),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <DoneIcon /> : <div></div>;
        },
      },
    },
    {
      name: 'sendEmail',
      label: 'Email',
      options: {
        display: true,
        sortOrder: getSortOrder('sendEmail'),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <DoneIcon /> : <div></div>;
        },
      },
    },
    {
      name: 'messageGroupId',
      label: 'Message Group ID',
      options: {
        display: false,
      },
    },
    {
      name: 'messageGroup',
      label: 'Message Group',
      options: {
        display: true,
        sortOrder: getSortOrder('messageGroupName'),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value || 'None'}
              component={Link}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
              to={
                value
                  ? `/main/admin/message-group/view/${tableMeta.rowData[7]}`
                  : `/main/admin/message-group`
              }
            />
          );
        },
      },
    },

    {
      name: 'typeDefault',
      label: 'Type Default',
      options: {
        display: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created On',
      options: {
        display: true,
        sortOrder: getSortOrder('createdAt'),

        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(parseISO(value, 'yyyy-MM-dd', new Date()), 'do MMM yyyy')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated On',
      options: {
        display: true,
        sortOrder: getSortOrder('updatedAt'),

        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body1">
              {format(parseISO(value, 'yyyy-MM-dd', new Date()), 'do MMM yyyy')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              component={Link}
              to={`/admin/message-template/view/${tableMeta.rowData[2]}`}
              label={value}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    downloadOptions: {
      filename: 'message-templates.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    serverSide: true,
    rowHover: false,
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    count: messageTemplates && messageTemplates.count,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      console.log(column);
      console.log(direction);

      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getMessageTemplates(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (messageTemplates) {
      var finalData =
        messageTemplates &&
        messageTemplates.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [messageTemplates]);

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            component={Link}
            to="/admin/message-template/add"
            className={classes.button}
            startIcon={<AddBoxIcon />}
          >
            Add Message Template
          </Button>

          <Button
            variant="outlined"
            size="large"
            component={Link}
            to="/admin/message-group"
            className={classes.button}
            startIcon={<GroupWorkIcon />}
          >
            Message Groups
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Message Template List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MessageTemplateList;
