import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import jwt from 'jsonwebtoken';
import { useSelector } from 'react-redux';
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
  ROLE_MANAGEMENT,
} from '../../features/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5, 2),
    padding: theme.spacing(2),
    marginTop: '4rem',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [url, setUrl] = useState(null);
  const darkMode = localStorage.getItem('darkMode');
  const organization = useSelector((state) => state.auth.organization);
  const currentUser = useSelector((state) => state.auth.current);

  useEffect(() => {
    if (currentUser) {
      const dkMode = darkMode === 'true' ? '#theme=night&' : '';

      let _dashboard;
      let params = {};
      if (currentUser.roleName === ROLE_SUPER_ADMIN) {
        _dashboard = 1;
      } else if (currentUser.roleName === ROLE_ADMIN) {
        _dashboard = 2;
        params.organizationid = currentUser.organizationId;
      } else if (currentUser.roleName === ROLE_MANAGEMENT) {
        _dashboard = 2;
        params.organizationid = currentUser.organizationId;
        // params.userid = currentUser.id;
      }

      const payload = {
        resource: { dashboard: _dashboard },
        params: params,
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };

      // console.log(process.env.REACT_APP_METABASE_SECRET_KEY);
      const token = jwt.sign(
        payload,
        process.env.REACT_APP_METABASE_SECRET_KEY,
      );
      // console.log(process.env.REACT_APP_METABASE_SECRET_KEY);
      // console.log(process.env.REACT_APP_METABASE_URL);
      //
      const iframeUrl =
        process.env.REACT_APP_METABASE_URL +
        '/embed/dashboard/' +
        token +
        `${dkMode}#bordered=true&titled=true`;

      setUrl(iframeUrl);
    }
    // eslint-disable-next-line
  }, [organization, currentUser]);

  // const url = process.e

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <iframe
            title="Dashboard"
            src={url && url}
            frameborder="0"
            // width="900"
            // height="600"
            allowtransparency
            style={{
              display: 'block' /* iframes are inline by default */,
              background: '#fff',
              border: 'none' /* Reset default border */,
              height: '100vh' /* Viewport-relative units */,
              width: '100%',
            }}
          ></iframe>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboard;
