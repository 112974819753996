import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import {
  getBillingByID,
  updateBilling,
} from '../../../features/billing/billingActions';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3, 2),
  },
  contentBox: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  badge: {
    padding: theme.spacing(1),
    borderRadius: '5px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
}));

const BillingView = ({ history, match }) => {
  const classes = useStyles();

  const billing = useSelector((state) => state.billing.current);
  const dispatch = useDispatch();
  const [transactionCostDialogOpen, setTransactionCostDialogOpen] =
    useState(false);

  useEffect(() => {
    if (match && match.params.id) {
      dispatch(getBillingByID(match.params.id));
    }
    // eslint-disable-next-line
  }, [match]);

  const [newTransactionCost, setNewTransactionCost] = useState(0);

  useEffect(() => {
    if (billing) {
      setNewTransactionCost(billing.transactionCost);
    }
    // eslint-disable-next-line
  }, [billing]);

  if (!billing) {
    return (
      <div className={classes.root}>
        <LinearProgress color="primary" />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Grid id="print" container spacing={4}>
          <Grid item xs={12} md={12}>
            <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
              <Typography variant="body1" color="textSecondary">
                Details
              </Typography>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={2}
                style={{ marginTop: '1rem' }}
              >
                {/* Transaction ID */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Organization:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography className={classes.badge} variant="button">
                    <strong>{billing.organizationName}</strong>
                  </Typography>
                </Grid>
                {/* Credit Amount */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Credit Amount</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography
                    label={billing.creditAmount}
                    variant="h4"
                    color="primary"
                  >
                    {billing.creditAmount}
                  </Typography>
                </Grid>
                {/* Wallet Balance */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Wallet Balance</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body1" color="primary">
                    Kshs {billing.walletBalance}
                  </Typography>
                </Grid>

                {/* Low Credit Level */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Low Credit Level</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body1" color="primary">
                    {billing.lowCreditLevel}
                  </Typography>
                </Grid>

                {/* Transaction Cost */}
                <Grid item xs={12} md={4}>
                  <Typography variant="body1">
                    <strong>Transaction Cost</strong>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1" color="primary">
                    Kshs {billing.transactionCost}
                  </Typography>
                  <Button
                    style={{ marginLeft: '1rem' }}
                    variant="outlined"
                    onClick={() => setTransactionCostDialogOpen(true)}
                    endIcon={<EditIcon />}
                  >
                    EDIT
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={transactionCostDialogOpen}
          onClose={() => setTransactionCostDialogOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Change Transaction Cost for {billing.organizationName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* To subscribe to this website, please enter your email address
              here. We will send updates occasionally. */}
            </DialogContentText>
            <TextField
              autoFocus
              type="number"
              onChange={(e) => setNewTransactionCost(e.target.value)}
              label="Transaction Cost in Kshs"
              variant="outlined"
              fullWidth
              value={newTransactionCost}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setTransactionCostDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                dispatch(
                  updateBilling(billing.organizationId, {
                    transactionCost: newTransactionCost,
                  }),
                );
                setTransactionCostDialogOpen(false);
              }}
              color="primary"
            >
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

export default BillingView;
