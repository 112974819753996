import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import Notification from './components/layout/Notification';
import Home from './pages/home/Home';
import setAuthToken from './utils/setAuthToken';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const App = () => {
  // Set Token
  setAuthToken(localStorage.getItem('token'));

  const organization = useSelector((state) => state.auth.organization);

  const darkMode = localStorage.getItem('darkMode');
  let primaryColor =
    localStorage.getItem('primaryColor') != null
      ? localStorage.getItem('primaryColor')
      : '#353535';
  let secondaryColor =
    localStorage.getItem('secondaryColor') != null
      ? localStorage.getItem('secondaryColor')
      : '#87CC2E';

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      type: darkMode === 'true' ? 'dark' : 'light',
    },
  });
  useEffect(() => {
    // Set Theme Color according to organization
    if (organization) {
      if (localStorage.getItem('primaryColor') == null) {
        localStorage.setItem('primaryColor', organization.primaryColor);
      }
      if (localStorage.getItem('secondaryColor') == null) {
        localStorage.setItem('secondaryColor', organization.secondaryColor);
      }
    }
  }, [organization]);

  return (
    <React.Fragment>
      <CssBaseline />
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={1}>
            <Notification
              style={{
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
            <Router>
              <Route path="(^)" render={(props) => <Home {...props} />} />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
