import {
  GET_TRANSACTION,
  GET_TRANSACTIONS,
  SUBMIT_TRANSACTION,
  TRANSACTION_ERROR,
  CLEAR_TRANSACTION,
} from './transactionTypes';
import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import { clearLoading } from '../notification/notificationActions';
const url = process.env.REACT_APP_API_URL + 'transactions';

// let notification;
// Get Transactions
export const getTransactions = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const transactions = res.data;
    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: GET_TRANSACTIONS, payload: transactions });
  } catch (e) {
    dispatch({ type: CLEAR_LOADING });
    console.error(e);
  }
};

// Get Transaction By ID
export const getTransactionById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);

    const transaction = res.data;
    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: GET_TRANSACTION, payload: transaction });
  } catch (e) {
    clearLoading();
    console.error(e);
  }
};

// Get Transaction Admin By ID
export const getTransactionAdminById =
  (id, organizationId) => async (dispatch) => {
    dispatch({ type: SET_LOADING });

    try {
      const res = await axios.get(`${url}/${id}/admin/${organizationId}`);

      const transaction = res.data;
      dispatch({ type: CLEAR_LOADING });
      dispatch({ type: GET_TRANSACTION, payload: transaction });
    } catch (e) {
      clearLoading();
      console.error(e);
    }
  };

// Submit Transaction
export const makeTransaction = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: SUBMIT_TRANSACTION, payload: res.data });
    // Set Register Success Notification

    // const transaction = res.data;
    const notification = {
      message: `Transaction Completed`,
      type: 'success',
    };
    // console.log(transaction);
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    console.log(error);

    // console.log(error.response.data)
    const message = error.response.data.message;
    const notification = {
      message:
        message ??
        'Ooops! there appears to be a problem. Please try again in 10 min',
      type: 'error',
    };

    // console.log(transaction);
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
      dispatch({ type: CLEAR_LOADING });
    }, 2000);

    dispatch({
      type: TRANSACTION_ERROR,
      payload: 'Failed to Complete Transaction. Contact Support',
    });
  }
};

// Update Transaction
export const updateTransaction = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: TRANSACTION_ERROR,
      payload: 'Failed to Update Transaction',
    });
  }
};

export const clearTransaction = () => async (dispatch) => {
  dispatch({ type: CLEAR_TRANSACTION });
  dispatch({ type: CLEAR_LOADING });
};
