import { SET_CURRENT_PAGE, CLEAR_CURRENT_PAGE } from './pagesTypes';

// Set Current Page

export const setCurrentPage = (path) => async (dispatch) => {
  const page = convertPathToPage(path.toString().toLowerCase());
  // console.log(page);

  dispatch({ type: SET_CURRENT_PAGE, payload: page });
};
// Clear Current Page

export const clearCurrentPage = async (dispatch) => {
  dispatch({ type: CLEAR_CURRENT_PAGE });
};

const convertPathToPage = (path) => {
  if (path.includes('help')) {
    return 'Help';
  }

  if (path.includes('customers/add')) {
    return 'Enroll Customer';
  }

  if (path.includes('customers')) {
    return 'Customers';
  }

  if (path.includes('transactions/add')) {
    return 'Add Transaction';
  }

  if (path.includes('transactions')) {
    return 'Transactions';
  }

  if (path.includes('transaction-types/add')) {
    return 'Add Transaction Type';
  }

  if (path.includes('transaction-types')) {
    return 'Transaction Types';
  }

  if (path.includes('billing/add')) {
    return 'Add Billing';
  }

  if (path.includes('verifications')) {
    return 'Verifications';
  }

  if (path.includes('message-template/add')) {
    return 'Message Template';
  }

  if (path.includes('message-template/edit/:id')) {
    return 'Edit Message Template';
  }

  if (path.includes('message-template/view/:id')) {
    return 'Message Template';
  }

  if (path.includes('message-template')) {
    return 'Message Templates';
  }

  if (path.includes('message-group/add')) {
    return 'Add Message Group';
  }

  if (path.includes('message-group/edit/:id')) {
    return 'Edit Message Group';
  }

  if (path.includes('message-group')) {
    return 'Message Groups';
  }

  if (path.includes('messaging/send')) {
    return 'Send Message';
  }

  if (path.includes('messaging')) {
    return 'Messages';
  }

  if (path.includes('transactions/add')) {
    return 'Add Transaction';
  }

  if (path.includes('billing')) {
    return 'Billing';
  }

  if (path.includes('/users/add')) {
    return 'Add User';
  }
  if (path.includes('/users/edit')) {
    return 'Update User';
  }

  if (path.includes('/users')) {
    return 'Users';
  }

  if (path.includes('/verify')) {
    return 'Verify';
  }

  if (path.includes('organizations/add')) {
    return 'Add Organization';
  }

  if (path.includes('organizations/edit')) {
    return 'Edit Organization';
  }

  if (path.includes('organizations')) {
    return 'Organizations';
  }

  if (path.includes('profile/organization')) {
    return 'Organization Settings';
  }

  if (path.includes('profile')) {
    return 'Profile';
  }

  if (path.includes('')) {
    return 'Dashboard';
  }
};
