import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../features/transaction/transactionActions';
import { Link } from 'react-router-dom';
import CustomSearchRender from '../../utils/CustomSearchRender';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns/esm';
import { parseISO } from 'date-fns';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from '../../features/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    // width: '100vw'
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const TransactionList = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);

  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transaction.transactions);
  const currentUser = useSelector((state) => state.auth.current);
  const loading = useSelector((state) => state.notification.loading);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        console.log(filter.sortOrder);
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        sortOrder: getSortOrder('id'),
        sort: true,
        download: true,
        print: true,
        viewColumns: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Date',
      options: {
        sortOrder: getSortOrder('createdAt'),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography label={value} variant="body1" color="primary">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'dd-MM-yyyy p',
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'duration',
      label: 'Duration',
      options: {
        sortOrder: getSortOrder('duration'),
        sort: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography label={value} variant="body1" color="primary">
              {parseInt(value / 1000)}s
            </Typography>
          );
        },
      },
    },
    {
      name: 'result',
      label: 'Result',
      options: {
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: 'message',
      label: 'Message',
      options: {
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: 'fullName',
      label: 'Full Name',
      options: {
        download: true,
        print: true,
        viewColumns: true,
        filter: false,
      },
    },
    {
      name: 'userId',
      label: 'User ID',
      options: {
        display: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: 'userName',
      label: 'Verified By',
      options: {
        display:
          currentUser &&
          (currentUser.roleName === ROLE_SUPER_ADMIN ||
            currentUser.roleName === ROLE_ADMIN),
        viewColumns:
          currentUser &&
          (currentUser.roleName === ROLE_SUPER_ADMIN ||
            currentUser.roleName === ROLE_ADMIN),
        print:
          currentUser &&
          (currentUser.roleName === ROLE_SUPER_ADMIN ||
            currentUser.roleName === ROLE_ADMIN),
        download:
          currentUser &&
          (currentUser.roleName === ROLE_SUPER_ADMIN ||
            currentUser.roleName === ROLE_ADMIN),
        filter:
          currentUser &&
          (currentUser.roleName === ROLE_SUPER_ADMIN ||
            currentUser.roleName === ROLE_ADMIN),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              component={Link}
              label={value}
              style={{ cursor: 'pointer' }}
              variant="outlined"
              to={`/admin/users/view/${tableMeta.rowData[6]}`}
              color="primary"
            />
          );
        },
      },
    },
    {
      name: 'organizationId',
      label: 'Organization ID',
      options: {
        sortOrder: getSortOrder('organizationId'),
        sort: false,
        display: false,
        viewColumns: false,
        download: false,
        print: false,
      },
    },
    {
      name: 'organizationName',
      label: 'Organization',
      options: {
        display: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        viewColumns: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        print: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        download: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        filter: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        sortOrder: getSortOrder('organizationName'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              component={Link}
              to={`/organizations/view/${tableMeta.rowData[8]}`}
              label={value}
              variant="body1"
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'idRecord',
      label: 'ID Record',
      options: {
        sort: false,
        display: false,
        viewColumns: false,
        download: false,
        print: false,
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              component={Link}
              to={
                currentUser && currentUser.roleName === ROLE_SUPER_ADMIN
                  ? `/admin/transactions/view/${tableMeta.rowData[0]}/admin/${tableMeta.rowData[8]}`
                  : `/admin/transactions/view/${tableMeta.rowData[0]}`
              }
              label={value}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    serverSide: true,
    responsive: 'vertical',
    rowHover: false,

    onTableChange: (action, tableState) => {
      // TODO : See On Table Change
      // console.log(`Action ${action}`);
      // console.log(`Table State ${JSON.stringify(tableState)}`);
      // this.xhrRequest('my.api.com/tableData', result => {
      //   this.setState({ data: result });
      // });
    },
    // onRowClick: (rowData, rowMeta) => {
    //   // rowData[2] -- Is Email
    //   history.push(`/transactions/view/${rowData[0]}`);
    // },
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    selectableRows: 'none',
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        // console.log(searchText);
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      // console.log(column);
      // console.log(direction);
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'asc' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getTransactions(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (transactions) {
      var finalData =
        transactions &&
        transactions.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [transactions]);

  return (
    <div>
      <Paper className={classes.root}>
        <Grid container spacing={3}>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress color="primary" />
            </Grid>
          )}
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Transactions List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default TransactionList;
