import {
  GET_PAYMENT,
  ADD_PAYMENT,
  DELETE_PAYMENT,
  UPDATE_PAYMENT,
  GET_PAYMENTS,
} from './paymentTypes';

const initialState = {
  payments: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };

    case ADD_PAYMENT:
      return {
        ...state,
        payments: {
          ...state.payments,
          data: 
          state.payments && state.payments.data.length>0 ?
          state.payments.data.concat(action.payload)
          :[action.payload],
          count:state.count+1
        },
      };
    case DELETE_PAYMENT:
      return {
        ...state,
        payments: {
          ...state.payments,
          data:
          state.payments && state.payments.data.length>0 ?
          state.payments.data.filter(
          (payment) => payment.id !== action.payload,
        ):state.payments.data
      }
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        payments:{
        ...state.payments,
        data:
        state.payments && state.payments.data.length>0?
          state.payments.data.map(
            (payment) =>
              (payment.id = action.payload.id
                ? action.payload
                : payment),
          ):
          state.payments.data
        }
      };
    case GET_PAYMENT:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};
