import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import CustomSearchRender from '../../utils/CustomSearchRender';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ROLE_SUPER_ADMIN } from '../../features/role/roleTypes';
import { getPayments } from '../../features/payment/paymentActions';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { numberFormatText } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    // width: '100vw'
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const PaymentList = ({ billingId, history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);

  const dispatch = useDispatch();

  const payments = useSelector((state) => state.payment.payments);
  const loading = useSelector((state) => state.notification.loading);
  const currentUser = useSelector((state) => state.auth.current);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
    billingId: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        console.log(filter.sortOrder);
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'organizationId',
      label: 'Organization ID',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'organizationName',
      label: 'Organization',
      options: {
        display: currentUser && currentUser.roleName === ROLE_SUPER_ADMIN,
        sortOrder: getSortOrder('organizationName'),
        filter: false,
        viewColumns: false,
        print: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value}
              component={Link}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        sortOrder: getSortOrder('type'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2">
              <strong>{value}</strong>
            </Typography>
          );
        },
      },
    },
    {
      name: 'amount',
      label: 'Amount(KES)',
      options: {
        sortOrder: getSortOrder('amount'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2">
              <strong>{numberFormatText(value, '')}</strong>
            </Typography>
          );
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Made on',
      options: {
        sortDirection: getSortOrder('createdAt'),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography label={value} variant="body1" color="primary">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'dd-MM-yyyy p',
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      options: {
        sortOrder: getSortOrder('notes'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2">
              <strong>{value}</strong>
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    serverSide: true,
    responsive: 'vertical',
    rowHover: false,
    selectableRows: 'none',
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    elevation: 0,
    count: payments && payments.count,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        // console.log(searchText);
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      // console.log(column);
      // console.log(direction);
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'asc' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getPayments(filter));
    // eslint-disable-next-line
  }, [filter]);

  // useEffect(()=>{
  //   if(billingId){
  //     setFilter({...filter,billingId})
  //   }
  //   // eslint-disable-next-line
  // },[billingId, filter])

  // When store/state data is updated
  useEffect(() => {
    if (payments) {
      var finalData =
        payments &&
        payments.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [payments]);
  if (loading) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {data && (
            <MUIDataTable
              title={'Wallet History'}
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentList;
