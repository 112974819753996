import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {
  getUserById,
  getUserByIdAdmin,
} from '../../../features/user/userActions';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProfileSettingsView from './ProfileSettingsView';
import { getRoles } from '../../../features/role/roleActions';
import AccountSettingsView from './AccountSettingsView';
import { ROLE_SUPER_ADMIN } from '../../../features/role/roleTypes';
import { stringAvatar } from '../../../utils/functions';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    margin: theme.spacing(10, 2),
  },
  card: {
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(0.3),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
  },
}));

const UserView = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current = useSelector((state) => state.user.current);
  const currentUser = useSelector((state) => state.auth.current);
  const roles = useSelector((state) => state.role.role);

  useEffect(() => {
    if (currentUser) {
      if (currentUser && currentUser.roleName === ROLE_SUPER_ADMIN) {
        dispatch(
          getUserByIdAdmin(match.params.id, match.params.organizationId),
        );
      } else {
        dispatch(getUserById(match.params.id));
      }
      dispatch(getRoles());
    }

    // eslint-disable-next-line
  }, [currentUser]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <Stack
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Avatar
                    alt={current.firstName}
                    className={classes.avatar}
                    style={{}}
                    {...stringAvatar(
                      `${current.firstName} ${current.lastName}`,
                    )}
                    sx={{ width: 100, height: 100 }}
                  />

                  <Stack direction="column">
                    <Typography variant="h6" className={classes.labelBadge}>
                      {current && current.firstName}{' '}
                      {current && current.lastName}
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      {current && current.email}
                    </Typography>
                    <br />

                    <Typography variant="body1" className={classes.labelBadge}>
                      <strong>{current && current.mobileNumber}</strong>
                    </Typography>
                    <br />
                    <Typography variant="body1" className={classes.labelBadge}>
                      <strong>{current && current.organizationName}</strong>
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                variant="fullWidth"
              >
                <Tab label="Profile" />
                <Tab label="Account" />
                {/* <Tab label="Notifications" /> */}
                {/* <Tab label="Item Two" />
          <Tab label="Item Three" /> */}
              </Tabs>

              <div>
                {value === 0 && (
                  <div>
                    <ProfileSettingsView user={current} />
                  </div>
                )}
              </div>
              <div>
                {value === 1 && (
                  <div>
                    <AccountSettingsView roles={roles} user={current} />
                  </div>
                )}
              </div>
              {/* <div>{value === 2 && <div>Hello Notifications</div>}</div> */}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default UserView;
