import { GET_ROLES, ROLE_SUPER_ADMIN } from './roleTypes';
import { CLEAR_LOADING, SET_LOADING } from '../notification/notificationTypes';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL;

// Get Roles
export const getRoles = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}roles`, {
    params: filter,
  });
  let roles = res.data;
  roles = roles.filter((role) => role.name !== ROLE_SUPER_ADMIN);
  dispatch({ type: GET_ROLES, payload: roles });
  dispatch({ type: CLEAR_LOADING });
};

// Add Role

// Get Role

// Update Role
