import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { updateCurrentOrganization } from '../../features/organization/organizationActions';
import { SketchPicker } from 'react-color';
import { useTheme } from '@mui/styles';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
// import { DropzoneDialog } from 'material-ui-dropzone';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  userCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  color: {
    width: '100%',
    height: '14px',
    borderRadius: '2px',
  },
  swatchPrimary: {
    padding: '1rem',
    background: theme.palette.primary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  swatchSecondary: {
    padding: '1rem',
    background: theme.palette.secondary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const OrganizationSettings = ({
  updateCurrentOrganization,
  notification,
  loading,
  organization,
  page,
  history,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const [form, setForm] = useState({
    name: null,
    description: null,
    address: null,
    mobileNo: null,
    email: null,
    image: null,
    enabled: true,
    primaryColor: theme.palette.primary,
    secondaryColor: theme.palette.secondary,
  });

  useEffect(() => {
    if (organization) {
      form.name = organization.name;
      form.description = organization.description;
      form.address = organization.address;
      form.mobileNo = organization.mobileNo;
      form.email = organization.email;
      form.image = organization.image;
      form.primaryColor = organization.primaryColor;
      form.secondaryColor = organization.secondaryColor;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [organization]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Save Colors
    localStorage.setItem('primaryColor', form.primaryColor);
    localStorage.setItem('secondaryColor', form.secondaryColor);
    //
    if (file) {
      // Upload Image First
      const data = new FormData();
      data.append('file', file);
      data.append(
        'upload_preset',
        process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
      );
      try {
        const res = await fetch(process.env.REACT_APP_CLOUDINARY_UPLOAD_URL, {
          method: 'POST',
          body: data,
        });
        const savedFile = await res.json();

        setForm({
          ...form,
          image: savedFile.secure_url,
        });

        updateCurrentOrganization({
          ...form,
          image: savedFile.secure_url,
        });
      } catch (error) {
        throw new Error(error);
      }
    } else {
      updateCurrentOrganization(form);
    }
  };

  // Image
  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [userImage, setUserImage] = useState('');
  const [file, setFile] = useState(null);

  const handleSave = (files) => {
    // Set Image
    const file = files[0];
    setFile(file);
    setUserImage(URL.createObjectURL(file));

    handleClose();
  };
  const handleClose = () => {
    setImagePickerOpen(false);
  };
  useEffect(() => {
    if (
      page &&
      page.includes('Organization Settings') &&
      notification &&
      notification.type === 'success'
    ) {
      window.location.reload();
      history.push('/admin');
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <VisibilitySensor>
              <Img
                style={{ maxHeight: '18rem' }}
                onClick={() => setImagePickerOpen(true)}
                src={
                  form.image
                    ? form.image
                    : userImage
                    ? userImage
                    : [
                        'https://place-hold.it/300x200/F8F8F8?text=Add User Image',
                      ]
                }
              />
            </VisibilitySensor>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={onChange}
                  id="name"
                  label="Name"
                  value={form.name}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  multiline
                  rows={2}
                  fullWidth
                  onChange={onChange}
                  id="description"
                  label="Enter Description"
                  value={form.description}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  onChange={onChange}
                  id="email"
                  label="Enter email"
                  value={form.email}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="mobileNo"
                  variant="outlined"
                  fullWidth
                  type="number"
                  onChange={onChange}
                  id="mobileNumber"
                  label="Mobile Number"
                  value={form.mobileNo}
                  autoFocus
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="address"
              variant="outlined"
              fullWidth
              type="text"
              onChange={onChange}
              id="address"
              label="Enter Address"
              value={form.address}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className={classes.contentBox}>
              <Typography variant="body1">Primary Color</Typography>
              <SketchPicker
                color={form.primaryColor}
                onChange={(color) => {
                  setForm({ ...form, primaryColor: color.hex });
                }}
                name="primaryColor"
              />
              <Typography variant="body1">Secondary Color</Typography>
              <SketchPicker
                color={form.secondaryColor}
                onChange={(color) => {
                  setForm({ ...form, secondaryColor: color.hex });
                }}
                name="secondaryColor"
              />
            </div>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          style={{ marginTop: '2rem' }}
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={!form.name || !form.description || (!file && !form.image)}
        >
          Update Organization
        </Button>
      </form>
      {/* <DropzoneDialog
        open={imagePickerOpen}
        onSave={handleSave}
        acceptedFiles={['image/*']}
        showPreviews={true}
        dropzoneText="Drag and drop an Image file here"
        fileLimit={1} // Select only one image
        showPreviewsInDropzone={true}
        showAlerts={true}
        maxFileSize={5000000}
        onClose={handleClose}
      /> */}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.notification.loading,
  page: state.page.page,
  notification: state.notification.notification,
  organization: state.auth.organization,
});

export default connect(mapStateToProps, { updateCurrentOrganization })(
  OrganizationSettings,
);
