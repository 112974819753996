import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { connect } from 'react-redux';
import {
  updateCurrentUser,
  saveCurrentUser,
} from '../../features/auth/authActions';
import { getRoles } from '../../features/role/roleActions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

export const Settings = ({
  current,
  updateCurrentUser,
  saveCurrentUser,
  getRoles,
  roles,
}) => {
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    mobileNumber: '',
    roleId: 'None',
  });
  const classes = useStyles();

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (current && current.firstName) {
      profile.firstName = current.firstName;
      profile.lastName = current.lastName;
      profile.userName = current.userName;
      profile.email = current.email && current.email;
      profile.roleId = current.roleId;
      profile.mobileNumber = current.mobileNumber;

      setProfile(profile);
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
    // changeSettings(profile);
  };

  useEffect(() => {
    updateCurrentUser(profile);

    // eslint-disable-next-line
  }, [profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveCurrentUser(profile);
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <div style={{ marginTop: 16 }}>
        <TextField
          id="firstName"
          name="firstName"
          value={profile.firstName}
          label="First Name"
          variant="outlined"
          onChange={onChange}
          fullWidth
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <TextField
          id="lastName"
          name="lastName"
          variant="outlined"
          label="Last Name"
          onChange={onChange}
          value={profile.lastName}
          fullWidth
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          name="email"
          value={profile.email}
          onChange={onChange}
          fullWidth
          disabled
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <TextField
          id="mobile Number"
          label="Mobile Number"
          variant="outlined"
          name="mobileNumber"
          value={profile.mobileNumber}
          onChange={onChange}
          fullWidth
          disabled
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          name="userName"
          value={profile.userName}
          onChange={onChange}
          fullWidth
        />
      </div>
      {/* {
      <FormControl
        style={{ marginTop: 16 }}
        fullWidth
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel ref={inputLabel} htmlFor="outlined-role-id-simple">
          Role
        </InputLabel>
        <Select
          fullWidth
          value={profile.roleId}
          onChange={onChange}
          name="roleId"
          labelWidth={labelWidth}
          inputProps={{
            name: 'roleId',
            id: 'outlined-role-id-simple'
          }}
        >
          <MenuItem value="None">
            <em>None</em>
          </MenuItem>
          {roles &&
            roles.map(role => (
              <MenuItem key={role.id} value={role.id}>
                <em>{role.name}</em>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
} */}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        Save
      </Button>
    </form>
  );
};
const mapStateToProps = (state) => ({
  current: state.auth.current,
  roles: state.role.roles,
});
export default connect(mapStateToProps, {
  updateCurrentUser,
  getRoles,
  saveCurrentUser,
})(Settings);
