import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { getBillings } from '../../features/billing/billingActions';
import CustomSearchRender from '../../utils/CustomSearchRender';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { GET_BILLING } from '../../features/billing/billingTypes';
import MakePaymentDialog from './MakePaymentDialog';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    // width: '100vw'
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const BillingList = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);

  const dispatch = useDispatch();

  const billings = useSelector((state) => state.billing.billings);
  const currentBilling = useSelector((state) => state.billing.current);
  const [makePaymentOpen, setMakePaymentOpen] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        console.log(filter.sortOrder);
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
      },
    },
    {
      name: 'organizationId',
      label: 'Organization ID',
      options: {
        display: false,
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'organizationName',
      label: 'Organization',
      options: {
        display: true,
        sortOrder: getSortOrder('organizationName'),
        filter: false,
        viewColumns: false,
        print: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={value}
              component={Link}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            />
          );
        },
      },
    },
    {
      name: 'lowCreditLevel',
      label: 'Low Credit Level(KES)',
      options: {
        sortOrder: getSortOrder('lowCreditLevel'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2">
              <strong>{value}</strong>
            </Typography>
          );
        },
      },
    },
    {
      name: 'transactionCost',
      label: 'Transaction Cost(KES)',
      options: {
        sortOrder: getSortOrder('transactionCost'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2">
              <strong>{value}</strong>
            </Typography>
          );
        },
      },
    },
    {
      name: 'walletBalance',
      label: 'Wallet Balance(KES)',
      options: {
        sortOrder: getSortOrder('walletBalance'),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2">
              <strong>{value}</strong>
            </Typography>
          );
        },
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                component={Link}
                to={`/admin/billing/view/${tableMeta.rowData[1]}`}
                label={value}
                variant="outlined"
                type="submit"
                color="secondary"
              >
                View
              </Button>
              <IconButton
                color="primary"
                onClick={() => {
                  const currentBilling = billings.data.find(
                    (billing) => (billing.id = tableMeta.rowData[0]),
                  );
                  dispatch({ type: GET_BILLING, payload: currentBilling });
                  setMakePaymentOpen(true);
                }}
              >
                <CardMembershipIcon />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    serverSide: true,
    responsive: 'vertical',
    rowHover: false,
    selectableRows: 'none',
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    count: billings && billings.count,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      const doSearch = (searchText) => {
        // console.log(searchText);
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      // console.log(column);
      // console.log(direction);
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'asc' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getBillings(filter));
    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (billings) {
      var finalData =
        billings &&
        billings.data &&
        billings.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [billings]);

  const handleMakePaymentClose = () => {
    dispatch({ type: GET_BILLING, payload: null });
    setMakePaymentOpen(false);
  };
  if (!billings) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<CardMembershipIcon />}
            size="large"
            onClick={() => history.push('/admin/billing/organization')}
          >
            MY BILLING
          </Button>
        </Grid>
        <Grid item xs={12}>
          {data && (
            <MUIDataTable
              title={'Billing List'}
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Grid>
      </Grid>
      {currentBilling && (
        <MakePaymentDialog
          makePaymentOpen={makePaymentOpen}
          handleMakePaymentClose={handleMakePaymentClose}
        />
      )}
    </div>
  );
};

export default BillingList;
