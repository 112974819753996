import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { BlockPicker } from 'react-color';
import { addOrganization } from '../../features/organization/organizationActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  userCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  color: {
    width: '100%',
    height: '14px',
    borderRadius: '2px',
  },
  swatchPrimary: {
    padding: '1rem',
    background: theme.palette.primary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  swatchSecondary: {
    padding: '1rem',
    background: theme.palette.secondary,
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AddOrganization = ({ history }) => {
  const classes = useStyles();

  const theme = useTheme();

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);

  const [form, setForm] = useState({
    id: null,
    name: null,
    description: null,
    email: null,
    mobileNo: null,
    address: null,
    active: false,
    primaryColor: theme.palette.primary,
    secondaryColor: theme.palette.secondary,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(addOrganization(form));
  };

  useEffect(() => {
    if (notification && notification.type === 'success') {
      history.push('/admin/organizations');
    }
    // eslint-disable-next-line
  }, [notification]);

  if (loading) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    id="name"
                    label="Name"
                    value={form.name}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="description"
                    variant="outlined"
                    fullWidth
                    type="text"
                    onChange={onChange}
                    id="description"
                    label="Description"
                    value={form.description}
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    onChange={onChange}
                    id="email"
                    label="Email"
                    value={form.email}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="mobileNo"
                    variant="outlined"
                    fullWidth
                    type="number"
                    onChange={onChange}
                    id="mobileNo"
                    label="Mobile No"
                    value={form.mobileNo}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="address"
                    variant="outlined"
                    fullWidth
                    type="text"
                    onChange={onChange}
                    id="address"
                    label="Address"
                    value={form.address}
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={form.active}
                      onChange={(e) =>
                        setForm({ ...form, active: e.target.checked })
                      }
                      value="active"
                      name="active"
                      color="primary"
                    />
                  }
                  value={form.active}
                  label="Enabled"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                <strong>Primary Color</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                <strong>Secondary Color</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <BlockPicker
                color={form.primaryColor}
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                ]}
                onChange={(color, event) => {
                  setForm({ ...form, primaryColor: color.hex });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BlockPicker
                color={form.secondaryColor}
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#EB144C',
                  '#F78DA7',
                  '#9900EF',
                ]}
                onChange={(color, event) => {
                  setForm({ ...form, secondaryColor: color.hex });
                }}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            style={{ marginTop: '2rem' }}
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!form.name || loading}
          >
            Add Organization
          </Button>
          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default AddOrganization;
