import {
  CREATE_ORGANIZATION,
  REMOVE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  GET_ORGANIZATION,
  GET_ORGANIZATIONS
} from './organizationTypes';

const initialState = {
  organizations: null,
  current: null,
  error: null,
  loading: false
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload
      };

    case CREATE_ORGANIZATION:
      return {
        ...state,
        organizations: state.organizations.concat(action.payload)
      };
    case REMOVE_ORGANIZATION:
      return {
        ...state,
        organizations: state.organizations.filter(
          organization => organization.id !== action.payload
        )
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organizations: state.organizations.map(
          organization =>
            (organization.id = action.payload.id
              ? action.payload
              : organization)
        )
      };
    case GET_ORGANIZATION:
      return {
        ...state,
        current: action.payload
      };

    default:
      return state;
  }
};
