export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION_STATS = 'GET_TRANSACTION_STATS';
export const SUBMIT_TRANSACTION = 'SUBMIT_TRANSACTION';
export const FILTER_TRANSACTION = 'FILTER_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';
