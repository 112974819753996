import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MoneyIcon from '@mui/icons-material/Money';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PAYMENT_TYPES_ORGANIZATION } from '../../features/payment/paymentTypes';
import { NumberFormatCustom } from '../../utils/functions';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { countries } from '../../utils/countries';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const BillingMakePayment = ({
  makePaymentOpen,
  handleMakePayment,
  handleMakePaymentClose,
}) => {
  const classes = useStyles();

  const loading = useSelector((state) => state.notification.loading);
  const billing = useSelector((state) => state.billing.current);
  const currentUser = useSelector((state) => state.auth.current);
  const organization = useSelector((state) => state.auth.organization);

  const [form, setForm] = useState({
    type: PAYMENT_TYPES_ORGANIZATION.CARD,
    amount: process.env.NODE_ENV === 'production' ? billing.transactionCost : 5,
    mobileNumber: currentUser.mobileNumber,
    sendReceipt: true,
    billingId: billing.id,
    creditAmount: 1,
    country: 'KE',
    currency: '',
  });

  const handleSubmit = () => {
    console.log('Make Payment');
    handleMakePayment(form);
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      open={makePaymentOpen}
      onClose={handleMakePaymentClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Fund {billing.organizationName}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-type-simple">
                Select Payment Type
              </InputLabel>
              <Select
                fullWidth
                value={form.type}
                onChange={onChange}
                name="type"
                inputProps={{
                  name: 'type',
                  id: 'outlined-type-simple',
                }}
              >
                {Object.entries(PAYMENT_TYPES_ORGANIZATION).map(
                  ([key, value]) => (
                    <MenuItem key={key} value={value}>
                      <em>{value.split('_').join(' ').toUpperCase()}</em>
                    </MenuItem>
                  ),
                )}
                {organization &&
                  countries
                    .filter((country) =>
                      organization.countries.includes(country.shortName),
                    )
                    .map((country) => country.paymentMethods)
                    .map((paymentMethod) => paymentMethod.toString())
                    .map((paymentMethod) => paymentMethod.toString())
                    .filter((a) => a.length > 0)
                    .map((paymentMethod, idx) => {
                      // console.log(paymentMethod);
                      return (
                        <MenuItem key={idx} value={paymentMethod}>
                          <em>
                            {paymentMethod.split('_').join(' ').toUpperCase()}
                          </em>
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="Amount"
              label="Amount in KES"
              onChange={(e) => {
                setForm({
                  ...form,
                  creditAmount: parseInt(
                    e.target.value / billing.transactionCost,
                  ),
                  amount: parseInt(e.target.value),
                });
              }}
              name="amount"
              value={form.amount}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    <MoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          fullWidth
          disabled={
            !form.creditAmount ||
            form.creditAmount.toString().length === 0 ||
            parseInt(form.creditAmount * billing.transactionCost) <
              parseInt(billing.transactionCost) ||
            loading
          }
          onClick={() => {
            handleSubmit();
          }}
          type="submit"
          color="primary"
          endIcon={<LocalAtmIcon />}
        >
          PAY KES {form.amount}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BillingMakePayment;
